import React from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ReactTagInput from "@pathofdev/react-tag-input";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Divider from '@material-ui/core/Divider';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import SidebarHelp from "components/Sidebar/SidebarHelp";
import GridItem from "components/Grid/GridItem.jsx";
import ServerApi from '../../modules/server-api';

class DeploymentWizard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      titleMsg: "",
      steps: ['Pilot details', 'Infrastructure configuration', 'Halo details'],
      activeStep: 0,
      reg: [],
      rids: [],
      tags: ["SKU", "Gender", "Size", "Color", "Department", "Style", "Brand"],
      customerName: '',
      rid: '',
      region: '',
      vm: '',
      mongovm:'',
      haloversion:[],
      subscription: '',
      vers: ''
    };
  }

  componentDidMount() {
    ServerApi.getVersionData(this);
  }


  handleNext = () => {
    const customer = {
      name: this.state.customerName,
      retail: this.state.rid,
      type: 'Pilot',
      status: 'In Progress',
      vmSize: this.state.vm,
      mongoVmSize: this.state.mongovm,
      region: this.state.region,
      version: this.state.vers,
      simulation: false,
      customAttributes: this.state.tags
    };

    if(this.state.activeStep === 2){
      this.props.history.push("/admin/fulfilmentstatus/"+this.state.rid);

      ServerApi.createPilot(customer, this);
    }
    this.setState({
      activeStep: this.state.activeStep + 1
    });

  };

  handleBack = (currentStep) => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  handleReset() {
    this.setState({
      activeStep: 0
    })
  };

  handleNameChange(event) {
    this.setState({
      customerName: event.target.value     
    }) 
  }; 

  handleridChange(event) {
    this.setState({
     rid: event.target.value     
    }) 
  }; 

  handleregionChange(event) {
    this.setState({
      region: event.target.value     
    }) 
  };

  handleVersionChange(event) {
    this.setState({
      vers: event.target.value     
    }) 
  };
  
  handleVMChange = (event) => {
    this.setState({
      vm: event.target.value   
    }) 
  };

  handleMongoVMChange = (event) => {
    this.setState({
      mongovm: event.target.value   
    }) 
  };
  addTags(newTags) {
    this.setState({ tags: newTags });
  }

  handleDrawerToggle = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  handleCancel = (e) => {
    this.props.history.push("/admin/table");
  }

  render() {
    const { classes } = this.props;
    const { reg, activeStep, steps, tags, sidebarOpen, titleMsg, haloversion } = this.state;

    return (
      <div>
        <SidebarHelp
          openState={sidebarOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          titleMsg={titleMsg}
        >
        </SidebarHelp>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader style={{ borderTop: "5px solid #4d933c" }}>
              <Typography>New Pilot Deployment
                <Tooltip title="Pilot deployment with preconfigured settings"><HelpIcon fontSize="small" style={{ paddingLeft: "5px", position: 'absolute' }} id="Demo Deployment" onClick={(e) => this.onSetSidebarOpen(e)} /></Tooltip> &nbsp;&nbsp;
                </Typography>
            </CardHeader>
            <Divider />
            <CardBody style={{ padding: "1.4em" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
              </Typography>
                  <Button onClick={(activeStep) => this.handleReset()} className={classes.button}>
                    Reset
            </Button>
                </React.Fragment>
              ) : (
                  <React.Fragment>
                      <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                <TextField className={`form-control ${this.state.customerNameError ? 'is-invalid' : ''}`}
                                  required
                                  id="customerName"
                                  name="customerName"
                                  label="Customer Name"
                                  fullWidth
                                  value={this.state.customerName} onChange={(event) => this.handleNameChange(event)}
                                />
                                <div className={classes.require}> {this.state.customerNameError} </div>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField className={`form-control ${this.state.retailerIdError ? 'is-invalid' : ''}`}
                                  required
                                  id="retailerId"
                                  name="retailerId"
                                  label="Retailer Id"
                                  fullWidth
                                  value={this.state.retailerId} onChange={(event) => this.handleridChange(event)}
                                />
                                <div className={classes.require}> {this.state.retailerIdError} </div>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <p className={classes.bottom2}>Region</p>
                                <Select required aria-required="true" id="dropdown"
                                  native
                                  input={
                                    <OutlinedInput className={classes.listRight} name="region" id="outlined-age-native-simple" />
                                  }
                                  onChange={(event) => this.handleregionChange(event)}
                                >
                                  <option >--Select--</option>
                                  <option>East US</option>
                                  <option>West Europe</option>
                                  <option>Central US</option>
                                  <option>East Asia</option>
                                  {reg.map((x, i) => <option key={i}>{x}</option>)}
                                </Select>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <p className={classes.bottom2}>Version </p>
                                <Select required aria-required="true" id="dropdown"
                                  native
                                  input={
                                    <OutlinedInput className={classes.listRight} name="region2" id="outlined-age-native-simple2" />
                                  }
                                  onChange={(event) => this.handleVersionChange(event)}
                                >
                                  <option >--Select--</option>
                                  {haloversion.map((x, i) => <option key={i}>{x}</option>)}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ display: activeStep === 1 ? 'block' : 'none' }}>
                        <Grid container>
                          <Grid item xs={10}>
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={6}>
                                <p>Core VM Configuration </p>
                                <Select required aria-required="true" id="dropdown" onChange={(event) => this.handleVMChange(event)}
                                  native
                                  input={
                                    <OutlinedInput name="vmconfig" id="outlined-age-native-simple" />
                                  }
                                >
                                  <option>--Select--</option>
                                  <option>Standard_A1_v2</option>
                                  <option>Standard_A4m_v2</option>
                                  <option>Standard_DS3_v2</option>
                                  <option>Standard_DS4_v2</option>
                                </Select>
                              </Grid>
                            <Grid item xs={12} sm={6}>
                                <p>Mongo VM Configuration </p>
                                <Select required aria-required="true" id="dropdown" onChange={(event) => this.handleMongoVMChange(event)}
                                  native
                                  input={
                                    <OutlinedInput name="vmconfig" id="outlined-age-native-simple" />
                                  }
                                >
                                  <option>--Select--</option>
                                  <option>Standard_A1_v2</option>
                                  <option>Standard_A4m_v2</option>
                                  <option>Standard_DS3_v2</option>
                                  <option>Standard_DS4_v2</option>
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      <div style={{ display: activeStep === 2 ? 'block' : 'none' }}>
                        <Grid item xs={12}>
                          <p className={classes.bottom}>Product Attributes </p>
                          <ReactTagInput
                            placeholder="Add Product Attributes ex: Color, Size"
                            maxTags={30}
                            tags={tags}
                            onChange={(newTags) => this.addTags(newTags)}
                          />
                        </Grid>
                      </div>
                      <Grid container item xs={12} sm={12} justify="flex-end">

                        <Button type="button" variant="contained" color="secondary" onClick={this.handleCancel}
                          className={classes.button}>
                          Cancel
                     </Button>

                        <Button disabled={activeStep === 0} onClick={(activeStep) => this.handleBack()} className={classes.button}>
                          Back
                       </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(activeStep) => this.handleNext()}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? 'Create' : 'Next'}
                        </Button>
                      </Grid>
                  </React.Fragment>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </div>
    );
  }
}

DeploymentWizard.propTypes = {
  classes: PropTypes.object
};

export default withStyles(dashboardStyle)(DeploymentWizard);
/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : New Deployment Creation screen
*/
import React from 'react';
import Grid from '@material-ui/core/Grid';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardActions from '@material-ui/core/CardActions';

import Button from '@material-ui/core/Button';

import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import "@pathofdev/react-tag-input/build/index.css";
import Divider from '@material-ui/core/Divider';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


class ProductionDeployment extends React.Component {

    handleCancel = (e) => {
        this.props.history.push("/admin/table");
      }

      
  render() {
    const { classes } = this.props;

    return (
      <div>
       <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader style={{ borderTop: "5px solid #80ccc4" }}>
                  <Typography>Production deployment</Typography>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs>
                        <Typography>Download the SOW Questionnaire and Create ECC Task for Production deployment</Typography>
                      </Grid>
                      <Grid>
                        <Typography>
                         
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </CardBody>
                <CardActions>
                    <Grid container item xs={12} sm={12} justify="flex-end">
                      <Button type="button" variant="contained" color="secondary" onClick={this.handleCancel}
                        className={classes.button}>
                        Cancel
                     </Button>
                    </Grid>
                  </CardActions>
              </Card>
            </GridItem>
          </GridContainer>
      </div>
    );
  }
}

ProductionDeployment.propTypes = {
  classes: PropTypes.object
};
export default withStyles(dashboardStyle)(ProductionDeployment);

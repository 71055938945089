/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Dynamic table to show data from azure web hook url 
*/
import React from 'react';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Moment from 'react-moment';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import ChartistGraph from "react-chartist";
import Button from 'components/CustomButtons/Button.jsx';

import DialogTitle from '@material-ui/core/DialogTitle';


export default class ReportsTable extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      openChart: false,
      usecasevalues: [],
      headerTitle: "",
      max: 0
    };
  }

  handleBack = (e) => {
    this.setState({
      openChart: false,
      usecasevalues: [],
      headerTitle: ""
    });
  };
  handleChart = (event, val, key) => {
    this.setState({
      openChart: true,
      usecasevalues: this.state.usecasevalues.concat(val),
      max: Math.max.apply(null, val),
      headerTitle: key
    });
  };


  getKeys = function () {
    if (this.props.data[0] === undefined) {
      return Object.keys(this.props.data);
    } else {
      return Object.keys(this.props.data[0]);
    }
  }

  getColumnDate = function () {
    return this.props.data.dataDate.map((data, index) => {
      return <TableCell key={index}>
        <Moment format="YYYY/MM/DD">
          {data}
        </Moment></TableCell>;
    })
  }

  getRowsData = function () {
    return Object.keys(this.props.data.dataVal).map((key, i) => {
      return <tr><TableCell>
        <Link component="button" onClick={event => this.handleChart(event, this.props.data.dataVal[key], key)}>
          <Tooltip title="Data Chart for last 7 days"><InsertChartIcon style={{ paddingtop: "-12px", position: "absolute" }} /></Tooltip>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {key}
      </TableCell>
        {this.getEachRow(this.props.data.dataVal[key])}
      </tr>
    })
  }

  getRowsData2 = function () {
    return Object.keys(this.props.data.processVal).map((key, i) => {
      return <tr><TableCell>
        <Link component="button" onClick={event => this.handleChart(event, this.props.data.processVal[key], key)}>
          <Tooltip title="Process Chart for last 7 days"><InsertChartIcon style={{ paddingtop: "-12px", position: "absolute" }} /></Tooltip>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {key}
      </TableCell>
        {this.getEachRow(this.props.data.processVal[key])}
      </tr>
    })
  }

  getEachRow = function (data) {
    return data.map((row, index) => {
      return <TableCell>{row}</TableCell>
    })
  }

  render() {
    var { openChart } = this.state;
    return (
      <div>
        <Table>
          <TableHead>
            <TableCell>Data </TableCell>
            {this.getColumnDate()}
          </TableHead>
          <TableBody>
            {this.getRowsData()}
          </TableBody>
          <TableHead>
            <TableCell>Process </TableCell>
            {this.getColumnDate()}
          </TableHead>
          <TableBody>
            {this.getRowsData2()}
          </TableBody>
        </Table>
        <Dialog open={openChart} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.state.headerTitle}</DialogTitle>
          <DialogContent>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart1"
                  data={{
                    series: [this.state.usecasevalues],
                    labels: this.props.data.dataDateShort
                  }}
                  type="Line"
                  options={{
                    low: 0,
                    high: this.state.max,
                    chartPadding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0
                    }
                  }
                  }
                />
              </CardHeader>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleBack} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}
import React from 'react'
import PropTypes from 'prop-types'

import {Route,Redirect} from 'react-router-dom'

import Auth from '../modules/auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    { ...rest }
    render={ props =>
      Auth.isUserAuthenticated() ? (
        Auth.isSupportUser() ? (
          <Redirect
          to={ {
            pathname: '/support',
            state: { from: props.location }
          } }
        />
        ):(
          Auth.isDeviceUser() ? (
            <Redirect
            to={ {
              pathname: '/device',
              state: { from: props.location }
            } }
          />
          ):(
            
          <Component { ...props } />
          )
        )
      ) : (
        <Redirect
          to={ {
            pathname: '/login',
            state: { from: props.location }
          } }
        />
      )
    }
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object
}

export default PrivateRoute
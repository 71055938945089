import jwt from 'jwt-decode';

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  /* eslint-disable no-undef */
  static authenticateUser (token) {
    localStorage.setItem('token', token)
  }

  static authNotified () {
    localStorage.setItem('authNotified', true)
  }

  static getAuthNotified () {
    return localStorage.getItem('authNotified')
  }

  static storeReferer (path) {
    localStorage.setItem('referer', path)
  }

  static getReferer () {
    return localStorage.getItem('referer')
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated () {
    var token = localStorage.getItem('token') ;
    if (token === null){
      return false;
    } else {
      var current_time = Date.now() / 1000;
      
      if ( jwt(token).exp < current_time) {
        return false;
      }else{
        return true;
      }
    }
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser () {
    localStorage.removeItem('token')
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken () {
    return localStorage.getItem('token')
  }

  //get user role 
  static isSupportUser () {
    var token = localStorage.getItem('token') ;
    if (token === null){
      return false;
    } else {
      return jwt(token).roles.includes("SUPPORT");
    }
  }
  //get user role 
  static isAdminUser () {
      var token = localStorage.getItem('token') ;
      if (token === null){
        return false;
      } else {
        return jwt(token).roles.includes("ADMIN");
      }
    }
  static getLoggedInUser () {
    var token = localStorage.getItem('token') ;
    if (token === null){
      return false;
    } else {
      return jwt(token).sub;
    }
  }

   //get device user role 
   static isDeviceUser () {
    var token = localStorage.getItem('token') ;
    if (token === null){
      return false;
    } else {
      return jwt(token).roles.includes("DEVICE");
    }
  }
}

export default Auth
/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Tejaswi
@company : Checkpoint Systems

@description : User profile password change screen 
*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from "@material-ui/core/Grid"
//import GridContainer from "components/Grid/GridContainer.jsx"
import Button from '@material-ui/core/Button'
//import Paper from '@material-ui/core/Paper'

import ServerApi from '../../modules/server-api';
//import * as Constants from 'constants/url.js';

import Alert from "components/Edit/Alert";
import Spinner from "components/Edit/Spinner";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  fontFamily: "sans-serif",
  textAlign: "center",

  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textAlign: "center",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  left: {
    marginLeft : "15px"
  },
  bottom: {
    marginBottom : "5px"
  },
  root: {
      flexGrow: 1,
  },
  button: {
    display: "flex",
    textAlign:"center",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "20px",    
  }
};

class UpdatePwd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      passChangeSuccess: false,
      hidden: true,
      msg: ''
    };
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  renderModal = () => {
    const onClick = () => {
      this.setState(() => ({ passChangeSuccess: false }))
    };
    return (
      <Alert isOpen={this.state.passChangeSuccess} onClose={this._handleClose} handleSubmit={onClick}
        title="Password Reset" text="Your password was changed successfully" submitButtonText="Done" />
    )
  };

  handleSubmit = ({ currentPass, newPass, confirmPass, setSubmitting, resetForm }) => {
    
    //console.log(confirmPass);
    const data = new FormData();
    data.append('oldPass',currentPass);
	  data.append('newPass',newPass);
    ServerApi.updateProfile(data,this);	

    // fake async login
    setTimeout(async () => {
      setSubmitting(false);
      this.setState(() => ({
        passChangeSuccess: true,
      }));
      resetForm()
    }, 1000);
    
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={{ currentPass: '', newPass: '', confirmPass: '' }}
        validationSchema={object().shape({
          currentPass: string().required('Current password is required'),
          newPass: string().required('New password is required'),
          confirmPass: string().oneOf([ref('newPass')],'Passwords do not match').required('Confirm New Password is required')
        })}
        onSubmit={( { currentPass, newPass, confirmPass }, { setSubmitting, resetForm }
          ) => this.handleSubmit({ currentPass, newPass, confirmPass, setSubmitting, resetForm })
        }
        render={props => {
          const { values, touched, errors, handleChange, handleBlur, handleSubmit, isValid, isSubmitting, } = props;
          return isSubmitting ? (
            <Spinner />
            ) : (
                       
              <form className="form" onSubmit={handleSubmit}>
                <FormControl fullWidth margin="dense">
                  <InputLabel htmlFor="password-current" error={Boolean(touched.currentPass && errors.currentPass)} >
                    {'Current Password'}
                  </InputLabel>
                  <Input id="password-current" name="currentPass" type={this.state.hidden ? "password" : "text"} 
                    value={values.currentPass} onChange={handleChange} onBlur={handleBlur}
                    error={Boolean(touched.currentPass && errors.currentPass)} />
                  <FormHelperText error={Boolean(touched.currentPass && errors.currentPass)}>
                    {touched.currentPass && errors.currentPass ? errors.currentPass : ''}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth margin="dense" error={Boolean(touched.newPass && errors.newPass)}>
                  <InputLabel htmlFor="password-new" error={Boolean(touched.newPass && errors.newPass)}>
                    {'New Password'}
                  </InputLabel>
                  <Input id="password-new" name="newPass" type={this.state.hidden ? "password" : "text"} 
                    value={values.newPass} onChange={handleChange} onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}/>
                  <FormHelperText error={Boolean(touched.newPass && errors.newPass)} >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth margin="dense" error={Boolean(touched.confirmPass && errors.confirmPass)} >
                  <InputLabel htmlFor="password-confirm" error={Boolean(touched.confirmPass && errors.confirmPass)} >
                    {'Confirm Password'}
                  </InputLabel>
                  <Input id="password-confirm"  name="confirmPass" type={this.state.hidden ? "password" : "text"} 
                    value={values.confirmPass} onChange={handleChange} onBlur={handleBlur}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}/>
                  <FormHelperText error={Boolean(touched.confirmPass && errors.confirmPass)}>
                    {touched.confirmPass && errors.confirmPass ? errors.confirmPass : ''}
                  </FormHelperText>
                </FormControl>                                                           
                
                <Grid container className={classes.root} direction="row"  justify="flex-end"  alignItems="center">
                  <Button variant="contained" color="primary" className={classes.button} onClick={this.toggleShow}> 
                    { this.state.hidden ? 'Show' : 'Hide' } </Button>
                  <Button type="submit" variant="contained" color="primary" className={classes.button} 
                      disabled={Boolean(!isValid || isSubmitting)} > Reset Password </Button> 
                  <Button variant="contained" color="secondary" className={classes.button} onClick={this.props.hideModal}> 
                    Cancel </Button>
                </Grid>                                                                   

                {this.renderModal()}              
              </form>
            )
          }}
        />
      )
    }
  }    

  UpdatePwd.propTypes = {
    classes: PropTypes.object
  };

export default withStyles(styles)(UpdatePwd);

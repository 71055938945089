import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';

import withStyles from "@material-ui/core/styles/withStyles";
import ServerApi from '../modules/server-api';

import * as VersionProps from 'constants/version.js';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  successIcon: {
    color: 'green',
    height: 40,
    width: 40,
  },
  errorIcon: {
    color: 'red',
    height: 40,
    width: 40,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  require: {
    color: "rgba(246,87,87)",
    fontSize: "12px"
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    margin: `10px auto`,
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: 10,
    marginLeft: 10,
  },
  bottom: {
    fontSize: "14px",
    marginBottom: "3px",
  },
  loginMsg: {
    color: "rgba(246,87,87)",
    fontSize: "14px"
  },
  cardStyle :{
    boxShadow: '0 0 0 0'
  }
};

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      disabledBtn: false,
      loginMsg: '',
      version: VersionProps.VERSION
    }
  }
  componentDidMount() {
    ServerApi.sessionStatus(this,true);
  }
  handleLoginIdChange = e => {
    this.setState({ email: e.target.value },
      () => { this.validateName(); });
  }

  handlePasswordChange = e => {
    this.setState({ password: e.target.value },
      () => { this.validateId(); });
  }

  validateName = () => {
    const { email } = this.state;
    this.setState({ loginIdError: email.length > 3 ? null : 'Must have minimum 4 characters' });
  }

  validateId = () => {
    const { password } = this.state;
    this.setState({ passwordError: password.length > 4 ? null : 'Must have minimum 5 characters' });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disabledBtn: true });
    ServerApi.login(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <Paper className={classes.paper}>
                  <Card className={classes.cardStyle}>
                    <CardHeader color="primary">
                      <h4 className={classes.cardTitleWhite}>Sign In to Halo OneView - Ops Suite</h4>
                    </CardHeader>
                    <CardBody>
                      <Grid container spacing={6}>
                        <Grid item xs={12} sm={6}>
                          <TextField className={`form-control ${this.state.loginIdError ? 'is-invalid' : ''}`}
                            required
                            id="email"
                            name="email"
                            label="Email Address"
                            fullWidth
			                      data-testid="email"
                            value={this.state.email || ''} onChange={this.handleLoginIdChange}
                          />
                          <div className={classes.require}> {this.state.loginIdError} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField className={`form-control ${this.state.passwordError ? 'is-invalid' : ''}`}
                            required
                            id="password"
                            name="password"
                            type="password"
                            label="Password"
							              data-testid="password"
                            fullWidth
                            value={this.state.password || ''} onChange={this.handlePasswordChange}
                          />
                          <div className={classes.require}> {this.state.paswordError} </div>
                        </Grid>
                        <Grid container item xs={12} spacing={4}>
                          <div className={classes.loginMsg}> {this.state.loginMsg} </div>
                        </Grid>
                        <Grid container item xs={12} spacing={3} justify="flex-end">
                          <Button type="submit" data-testid="submit" variant="contained"  disabled={this.state.disabledBtn} color="primary" className={classes.button}>
                            Sign In
                        </Button>
                        </Grid>
                      </Grid>
                    </CardBody>
                    <CardFooter>
                      <div class="text-muted">
                         v{this.state.version}
                      </div>
                    </CardFooter>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
     </form>
    );
  }
}
export default withStyles(styles)(Login);
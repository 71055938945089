import Auth from './auth';
import * as Constants from 'constants/url.js';

class ServerApi {
  static getCustomers(curObj) {
    fetch(Constants.GET_CUSTOMERS, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      },
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          customers: json
        })
      });
  }

  static getCustomerCount(curObj) {
    fetch(Constants.GET_TOTAL_DEPLOYMENT, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          deployment: json.count
        })
      });
  }

  static getCustomerDetails(curObj, rid) {
    fetch(Constants.GET_CUSTOMER_RETAIL + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        var prodHeaders = json.metadata.prodAttr.map(attr => {
          return attr;
        });

        curObj.setState({
          deployment: json,
          prodAttr: json.metadata.prodAttr,
          userProdAttr: json.metadata.prodAttr,
          locations: json.metadata.location,
          prodHeaders: prodHeaders
        })
      });
  }

  static login(curObj) {
    fetch(Constants.POST_LOGIN_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(curObj.state)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.token) {
          Auth.authenticateUser(data.token);
          curObj.setState({ loginMsg: "Logged in Sucessfully" });
          if ( curObj.props.history){
            curObj.props.history.push("/admin");
          } else{
            window.location.reload();
          }
        } else {
          //not a valid user 
          curObj.setState({ loginMsg: "Invalid username / password" });
          curObj.setState({ disabledBtn: false });
        }
      })
      .catch((error) => {
        console.log('error: ' + error);
        if (error.toString().includes("Failed to fetch")) {
          curObj.setState({ loginMsg: " Our server's are down, but we'll have things back to normal soon" });
          curObj.setState({ disabledBtn: false });
        }
      });
  }
  static fetchLog(curObj, statusType, rid) {
    fetch(Constants.GET_LOG_URL + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          logs: json,
          anchorEl: null,
          currentRetail: rid,
          statusType: statusType
        })
      });
  }

  static fetchLogLive(retail, curObj) {

    fetch(Constants.GET_LOG_URL + "/" + retail, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        json.forEach(steps => {

          if (steps.step === 1 && steps.status === "In Progress") {
            curObj.setState({ step1loading: true })
          } else if (steps.step === 1 && steps.status === "Done") {
            curObj.setState({ step1loading: false })
            curObj.setState({ step1done: true })
          } else if (steps.step === 1 && steps.status === "Error") {
            curObj.setState({ step1loading: false })
            curObj.setState({ step1error: true })
          }

          if (steps.step === 2 && steps.status === "In Progress") {
            curObj.setState({ step1loading: false })
            curObj.setState({ step2loading: true })
          } else if (steps.step === 2 && steps.status === "Done") {
            curObj.setState({ step2loading: false })
            curObj.setState({ step2done: true })
          } else if (steps.step === 2 && steps.status === "Error") {
            curObj.setState({ step2loading: false })
            curObj.setState({ step2error: true })
          }

          if (steps.step === 3 && steps.status === "In Progress") {
            curObj.setState({ step2loading: false })
            curObj.setState({ step3loading: true })
          } else if (steps.step === 3 && steps.status === "Done") {
            curObj.setState({ step3loading: false })
            curObj.setState({ step3done: true })
          } else if (steps.step === 3 && steps.status === "Error") {
            curObj.setState({ step3loading: false })
            curObj.setState({ step3error: true })
          }

          if (steps.step === 4 && steps.status === "In Progress") {
            curObj.setState({ step3loading: false })
            curObj.setState({ step4loading: true })
          } else if (steps.step === 4 && steps.status === "Done") {
            curObj.setState({ step4loading: false })
            curObj.setState({ step4done: true })
          } else if (steps.step === 4 && steps.status === "Error") {
            curObj.setState({ step4loading: false })
            curObj.setState({ step4error: true })
          }

          if (steps.step === 5 && steps.status === "In Progress") {
            curObj.setState({ step4loading: false })
            curObj.setState({ step5loading: true })
          } else if (steps.step === 5 && steps.status === "Done") {
            curObj.setState({ step5loading: false })
            curObj.setState({ step5done: true })
          } else if (steps.step === 5 && steps.status === "Error") {
            curObj.setState({ step5loading: false })
            curObj.setState({ step5error: true })
          }

          if (steps.step === 6 && steps.status === "In Progress") {
            curObj.setState({ step5loading: false })
            curObj.setState({ step6loading: true })
          } else if (steps.step === 6 && steps.status === "Done") {
            curObj.setState({ step6loading: false })
            curObj.setState({ step6done: true })
          } else if (steps.step === 6 && steps.status === "Error") {
            curObj.setState({ step6loading: false })
            curObj.setState({ step6error: true })
          }

          if (steps.step === 7 && steps.status === "In Progress") {
            curObj.setState({ step6loading: false })
            curObj.setState({ step7loading: true })
          } else if (steps.step === 7 && steps.status === "Done") {
            curObj.setState({ step7loading: false })
            curObj.setState({ step7done: true })
          } else if (steps.step === 7 && steps.status === "Error") {
            curObj.setState({ step7loading: false })
            curObj.setState({ step7error: true })
          }

          if (steps.step === 8 && steps.status === "In Progress") {
            curObj.setState({ step7loading: false })
            curObj.setState({ step8loading: true })
          } else if (steps.step === 8 && steps.status === "Done") {
            curObj.setState({ step8loading: false })
            curObj.setState({ step8done: true })
          } else if (steps.step === 8 && steps.status === "Error") {
            curObj.setState({ step8loading: false })
            curObj.setState({ step8error: true })
          }

          if (steps.step === 9 && steps.status === "In Progress") {
            curObj.setState({ step8loading: false })
            curObj.setState({ step9loading: true })
          } else if (steps.step === 9 && steps.status === "Done") {
            curObj.setState({ step9loading: false })
            curObj.setState({ step9done: true })
          } else if (steps.step === 9 && steps.status === "Error") {
            curObj.setState({ step9loading: false })
            curObj.setState({ step9error: true })
          }
        });
      });
  }

  static createDemoDeployment(customer, curObj) {

    fetch(Constants.POST_CLOUD_CREATE_DEMO, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: JSON.stringify(customer)
    }).then(function (data) {
      //
    });
  }



  static uploadLocationFile(url, data, curObj) {
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          fileprogress: false,
          statusMsgLocation: json.responseMsg
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static sessionStatus(curObj, login) {
    fetch(Constants.GET_SESSIONSTATUS, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken(),
        'Access-Control-Allow-Origin':'*'
      }),
    })
      .then(res => res.json())
      .then(json => {
        if (login === true && json === true) {
          curObj.setState({
            serverDown: false,
            loginAgain: false
          });
          curObj.props.history.push("/admin");
        } else if (json === false) {
          curObj.setState({
            loginAgain: true,
            loggedIn: false,
            serverDown: false
          });
          //curObj.props.history.push("/login");
        }
      })
      .catch((error) => {
        console.log('error: ' + error);
        if (error.toString().includes("Failed to fetch")) {
          curObj.setState({
            serverDown: true
          });
        }
      });
  }

  static uploadProductFile(url, data, curObj) {
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        curObj.setState({
          fileprogressProduct: false,
          statusMsgProduct: json.responseMsg
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getDashboardData(curObj) {
    fetch(Constants.GET_CUST_DASHBOARD, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      })
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          deployments: json
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static fetchDiagonstic(curObj, rid) {
    fetch(Constants.GET_CUST_DIAGNOSTIC + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        var dData = [];
        if (json.hasOwnProperty("diagnostic_data")) {
          dData = json.diagnostic_data;
        }
        curObj.setState({
          open: true,
          diagnostics: dData,
          anchorEl: null,
          isLoading: false
        })
      });
  }

  static getUserDetails(curObj) {
    fetch(Constants.GET_USER_DETAILS, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          userdata: json,
          userIntegration: json.integrationInfo,
          selectedImage: json.thumbnail
        })
      });
  }

  static getIntegrationDetails(curObj) {
    fetch(Constants.GET_INTEGRATION_DETAILS, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          integrationDetails: json
        })
      });
  }

  static getIntegrationType(curObj, type) {
    fetch(Constants.GET_INTEGRATION_TYPE + "/" + type, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          integrationType: json
        })
      });
  }

  static integrationAuth(curObj, jsonAuth, type, url, rid, fileCat, filename, userSelectedAttribute, columnLen) {

    const data = new FormData()
    fileCat["fileType"] = "Product";
    fileCat["fileName"] = filename;
    fileCat["userSelectedAttribute"] = userSelectedAttribute;
    fileCat["columnSize"] = columnLen;
    console.log(fileCat);

    data.append('jsonAuth', JSON.stringify(jsonAuth));
    data.append('type', type);
    data.append('url', url);
    data.append('rid', rid);
    data.append('fileCat', JSON.stringify(fileCat))

    fetch(Constants.POST_INTEGRATION_API, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          fileprogressProduct: false,
          fileprogress: false,
          integrationOpen: false,
          openPrdAttr: false,
          statusMsg: json.responseMsg
        });
      })
      .catch((error) => {
        curObj.setState({
          fileprogress: false,
          fileprogressProduct: false,
          integrationOpen: false,
          openPrdAttr: false,
          errormsg3: "Error in uploading product data"
        });
      });
  }


  static fetchMonitoring(curObj, rid) {
    fetch(Constants.GET_MONITOR_BY_RID + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          open: true,
          monitoring: json,
          anchorEl: null
        })
      });
  }


  static getNotification(curObj, rflag) { //fetching all unread notification
    fetch(Constants.GET_NOTIFICATION_ALL,
      {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Auth.getToken()
        }),
      })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          notifications: json
        })
      });
  }
  static setNotificationRead(id, curObj) {   // posting notification id which was opened
    fetch(Constants.POST_NOTIFICATION_ID + "?id=" + id,
      {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Auth.getToken()
        })
      })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          msg: "Profile Updated"
        });
      })
      .catch((error) => {
      });
  }
  static getNotificationId(curObj, page, rflag) {   //fetching notification by pagination
    if (page === "") {
      page = 0;
    }
    fetch(Constants.GET_NOTIFICATION_PAGE + "?page=" + page,
      {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Auth.getToken()
        }),
      })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          notifications: json
        })
      });
  }
  static fetchAvailibility(curObj, rid) {
    fetch(Constants.GET_DEP_AVAILABILITY + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          uptimedata: json.data,
          anchorEl: null,
          openAvailibilty: true,
          rid: rid
        })
      });
  }
  static updateProfile(data, curObj) {
    fetch(Constants.POST_UPDATE_PROFILE, {
      method: 'POST',
      headers: new Headers({

        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          msg: "Profile Updated"
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static postConfigLevels(data, rid, curObj) {
    const data1 = new FormData()
    data1.append('jsonData', JSON.stringify(data));
    data1.append('rid', rid);
    fetch(Constants.POST_UPD_PRODUCT_LEVEL, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data1
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          disabled1: false,
          updatemsg1: "Product Configuration Updated Successfully"
        });
      })
      .catch((error) => {
        curObj.setState({
          disabled1: false,
          errormsg1: "Error in updating Product Configuration"
        });
      });
  }

  static hhuiconfig(display, filter, scan, rid, curObj) {
    const data = new FormData()
    data.append('prodDisplay', display);
    data.append('filter', filter);
    data.append('scan', scan);
    data.append('rid', rid);
    fetch(Constants.POST_HHUI_CONFIG, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          disabled2: false,
          updatemsg2: "HHUI Configuration Updated Successfully"
        });
      })
      .catch((error) => {
        curObj.setState({
          disabled2: false,
          errormsg2: "Error in updating HHUI Configuration"
        });
      });
  }

  static getproperty(curObj, rid) {
    fetch(Constants.GET_PROPERTY + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        if (Object.keys(json).length > 0) {
          var count = Object.keys(json.productLevel).length;
          curObj.setState({
            displayAttr: json.productDisplay,
            filterAttr: json.filter,
            scanAttr: json.scan[0],
            levels: count,
            levelArr: json.productLevel
          })
        }
      });
  }

  static deleteDeployment(curObj, rid) {
    fetch(Constants.GET_DELETE_DEPLOYMENT + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        this.getCustomers(curObj);
      });
  }

  static updateDeployment(curObj, rid) {
    fetch(Constants.PUT_UPDATE_DEPLOYMENT_ACTIVE + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        this.getCustomers(curObj);
      });
  }

  static locAuth(jsonAuth, type, url, fileCat, sName, rid, curObj) {
    const data = new FormData()
    fileCat["siteName"] = sName;
    data.append('jsonAuth', JSON.stringify(jsonAuth));
    data.append('type', type);
    data.append('url', url);
    data.append('rid', rid);
    data.append('fileCat', JSON.stringify(fileCat));
    fetch(Constants.POST_INTEGRATION_API, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data
    })
      .then(json => {
        var existingLocation = curObj.state.locations;
        var newLoc = { "site_name": sName }
        existingLocation.unshift(newLoc);
        curObj.setState({
          locations: existingLocation,
          respmsg: "Location Added Successfully",
          integrationLocOpen: false
        });
      })
      .catch((error) => {
        curObj.setState({
          integrationLocOpen: false,
          errormsg: "Error in adding new location"
        });
      });
  }

  static getdynamicfile(curObj, rid) {
    fetch(Constants.GET_DYNAMIC_FILE + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          dynfilevalue: json.contents
        })
        console.log(json);
      });
  }
  static getCustomtable(curObj, file) {
    const data = new FormData()
    data.append('file', file)
    fetch(Constants.POST_CUSTOM_TABLE_API, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: data,
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          fileprogressProduct: false,
          fileprogress: false,
          integrationOpen: false,
          statusMsg: json.responseMsg,
          csvdata: json.content,
          csvColumn: json.content[0],
          csvColumnSize: json.content[0].length,
          csvfilename: json.filename
        });
      })
      .catch((error) => {
        curObj.setState({
          fileprogress: false,
          fileprogressProduct: false,
          integrationOpen: false
        });
      });
  }

  static getUserDepData(curObj) {
    fetch(Constants.GET_USER_DEP_DATA, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          reg: json.region,
          rids: json.rid,
          version: json.version
        })
      });
  }

  static updateVMStatus(curObj, rid) {
    fetch(Constants.GET_UPDATE_VMSTATUS + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
      });
  }

  static setAlertValue(curObj, rid) {
    fetch(Constants.GET_UPDATE_MONITOR_ALERT + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        ServerApi.getDashboardData(curObj);
      });
  }

  static createPilot(customer, curObj) {
    fetch(Constants.POST_CREATE_PILOT, {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: JSON.stringify(customer)
    }).then(function (data) {
      curObj.setState({
        loading: true,
        loadingMsg: "Login to Halo at https://halo.checkpointsystems.com",
        status: 'Active'
      });
    });
  }

  static addNewMonitoring(curObj, name, rid, monitorURL, email, interval) {
    const data = {};
    data['name'] = name;
    data['rid']= rid;
    data['monitorURL']= monitorURL;
    data['email']=email;
    data['interval']=interval;

    fetch(Constants.POST_ADD_NEW_MONITORING, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          disabled: false,
          availabilityDialog: false
        });
        ServerApi.getDashboardData(curObj);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static deleteMonitoring(curObj, retail) {
    fetch(Constants.GET_DELETE_MONITORING + "/" + retail, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          DeleteDialog: false
        });
        ServerApi.getDashboardData(curObj);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static editMonitoring(curObj, name, monitorURL, email, interval,timezone,monitorfrom,monitortill,rid) {
    const data = {};
    data['name'] = name;
    data['endpoint']= monitorURL;
    data['alertemail']=email;
    data['interval']=interval;
    data['timezone']=timezone;
    data['rid']=rid;
    data['monitorfrom']=monitorfrom;
    data['monitortill']=monitortill;

    fetch(Constants.POST_EDIT_MONITORING, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          disabled: false,
          EditDialog: false
        });
        ServerApi.getDashboardData(curObj);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getMonitoringDetails(curObj, retail) {
    fetch(Constants.GET_MONITORING_DETAILS + "/" + retail, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          nameUpdate: json.name,
          monitorurlUpdate: json.endpoint,
          emailsUpdate: json.alertemail,
          intervalUpdate: json.interval,
          timezoneUpdate: json.timezone,
          monitorfrom: json.monitorfrom,
          monitortill: json.monitortill,
          ridConfirm2: json.rid
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  
  static getVersionData(curObj) {
    fetch(Constants.GET_VERSION_DATA, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        curObj.setState({
          haloversion: json.version
        })
      });
  }

  static fetchReport(curObj, rid) {
    fetch(Constants.GET_REPORT_DATA + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        curObj.setState({
          reportdata: json,
          anchorEl: null,
          openReport: true,
          rid: rid
        })
      });
  }

  static getAllReports(curObj) {
    fetch(Constants.GET_ALL_REPORTS, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        curObj.setState({
          allreports: json,
          anchorEl: null
        })
      });
  }

  static fetchDevices(curObj, rid) {
    fetch(Constants.GET_DEVICES + "/" + rid, {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Auth.getToken()
      }),
    })
      .then(res => res.json())
      .then(json => {
        if (Object.keys(json).length !== 0){
          var latV=json[0].geometry[0];
          var lngV=json[0].geometry[1];
        
          curObj.setState({
            currentLocation: { lat: latV, lng: lngV },
          })
        }
        curObj.setState({
          venues: json,
          anchorEl: null,
          rid: rid
        })
      }).catch((error) => {
        console.log(error);
        window.location.href = "/";
      });
  }

  static updateDeviceLatLng(lat,lng,deviceId,rid) {
   
    const str ='?rid='+rid+'&lat='+lat+'&lng='+lng+'&deviceId='+deviceId;
     
    fetch(Constants.PUT_DEVICE_LAT_LNG+str, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      })
    })
   .then(res => res.json())
   .then(json => {
    
    });
  }

  static toggleDeviceStatus(deviceId,rid,status) {

    const str ='?rid='+rid+'&statusValue='+status+'&deviceId='+deviceId;
   
    fetch(Constants.PUT_DEVICE_STATUS+str, {
      method: 'PUT',
      headers: new Headers({
        'Authorization': 'Bearer ' + Auth.getToken()
      })
    })
   .then(res => res.json())
   .then(json => {
    
    });
  }
}
export default ServerApi
 /*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : New Deployment status screen
*/
import React from 'react';
import Grid from '@material-ui/core/Grid';

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Paper from '@material-ui/core/Paper';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import ServerApi from '../../modules/server-api';
import Divider from '@material-ui/core/Divider';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
};


class FulfilmentStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          step1loading: true, step2loading: false, step3loading: false, step4loading: false,
          step5loading: false, step6loading: false, step7loading: false, step8loading: false, step9loading: false,
          step1done: false, step2done: false, step3done: false, step4done: false,
          step5done: false, step6done: false, step7done: false, step8done: false, step9done: false,
          step1error: false, step2error: false, step3error: false, step4error: false,
          step5error: false, step6error: false, step7error: false, step8error: false, step9error: false
        };
      }
    
    componentDidMount() {
        const { rid } = this.props.match.params;
        this.timer = setInterval(() => ServerApi.fetchLogLive(rid, this), 10000);
    }
    render() {
        const { classes } = this.props;
      
        return (
          <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader style={{ borderTop: "5px solid #80ccc4" }}>
                  <Typography>Please wait while we perform below steps to bring 'Halo Experience' to you ...</Typography>
                </CardHeader>
                <Divider />
                <CardBody>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
                <Divider style={{marginTop:"50px"}} />
                <React.Fragment>
                {this.state.step1loading ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                             Store Inventory Management
                             </Typography>
                             <Typography>
                              RFID-based solutions help retailers improve their in-store inventory accuracy and on-shelf availability to provide great shopper experiences while maximizing their capital investments.
                             </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>

                 <React.Fragment>
                {this.state.step2loading ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                             Store Operations
                             </Typography>
                             <Typography>
                             Real-time data capture from various sensor technologies around the store help retailers deliver heightened customer service for improved shopping experiences and loss reductions.
                            </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>

                 <React.Fragment>
                {this.state.step3loading ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                             DC solutions
                             </Typography>
                             <Typography>
                             RFID-based solutions help retailers improve inventory accuracy at distribution centers, increase efficiencies, reduce shipping errors and support rapid omni-channel delivery.
                            </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>
                 <React.Fragment>
                {this.state.step4loading ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                             Supply Chain control
                             </Typography>
                             <Typography>
                             RFID-based solutions help retailers track and trace stock, improving their inventory accuracy, minimizing loss and maximizing their capital investments.
                            </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>
                 <React.Fragment>
                {this.state.step5loading || this.state.step6loading || this.state.step7loading || this.state.step8loading || this.state.step9loading?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                             Omni-Channel
                             </Typography>
                             <Typography>
                             RFID-based solutions help retailers reduce errors, improve efficiencies and deliver seamless omni-channel experiences for their customers.
                            </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>
                 <React.Fragment>
                {this.state.step9done ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                            Your Deployment is ready 
                            </Typography>
                            
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>
                 <React.Fragment>
                {this.state.step9error ?
                         <Paper className={classes.paper}>
                         <Grid container wrap="nowrap" spacing={2}>
                           <Grid item>
                           </Grid>
                           <Grid>
                             <Typography style={{fontWeight:"bold"}}>
                              Issue with deployment - Contact support
                            </Typography>
                           </Grid>
                         </Grid>
                       </Paper>
                    :null}
                 </React.Fragment>
              </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          </div>
    );
  }
}

FulfilmentStatus.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(FulfilmentStatus);

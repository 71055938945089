import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import TableList from "views/TableList/TableList.jsx";
import NewCustomer from "views/Fulfilment/NewCustomer.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import CustomerDetail from "views/Fulfilment/CustomerDetail.jsx";
import AvailabilityDetail from "views/Availability/AvailabilityDetail.jsx";
import DeploymentWizard from "views/Fulfilment/DeploymentWizard.jsx";
import ProductionDeployment from "views/Fulfilment/ProductionDeployment.jsx";
import FulfilmentStatus from "views/Fulfilment/FulfilmentStatus.jsx";
//import PilotEstimation from "views/Estimation/PilotEstimation.jsx";
import Onboarding from "views/OnboardingTemplate/Onboarding.jsx";
import Zookeeper from "./views/Zookeeper/Zookeeper.tsx";
import Version from "views/Version/Version.tsx";
import SignUp from "views/UserManagement/SignUp.tsx";
import KPI from "views/KPI/KPI.tsx";
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';


//import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TableChartIcon from '@material-ui/icons/TableChart';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';
import TimelineIcon from '@material-ui/icons/Timeline';

import Reports from "views/Reports/Reports.jsx";

import Auth from "modules/auth";



var boolDeployment = true;
if (process.env.REACT_APP_DEPLOYMENT_DISPLAY === "false") {
  boolDeployment = false;
}
var boolMonitoring = true;

if (process.env.REACT_APP_MONITORING_DISPLAY === "false") {
  boolMonitoring = false;
}

var boolReports = true;

if (process.env.REACT_APP_REPORTS_DISPLAY === "false") {
  boolReports = false;
}
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Monitoring",
    icon: EventAvailableIcon,
    component: DashboardPage,
    layout: "/admin",
    visibility: boolMonitoring
  },
  {
    path: "/table",
    name: "Deployments",
    icon: WebAssetIcon,
    component: TableList,
    layout: "/admin",
    visibility: boolDeployment
  },
  {
    path: "/reports",
    name: "Reports",
    icon: TableChartIcon,
    component: Reports,
    layout: "/admin",
    visibility: boolReports
  },
  /*{
    path: "/estimation/pilot",
    name: "Estimation - Pilot [Beta]",
    icon: AttachMoneyIcon,
    component: PilotEstimation,
    layout: "/admin",
    visibility:true
  },*/
  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    visibility: true
  },
  {
    path: "/notification/pagewise",
    name: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
    visibility: true
  },
  {
    path: "/zookeeper",
    name: "Zookeeper",
    icon: DescriptionIcon,
    component: Zookeeper,
    layout: "/admin",
    visibility: true
  },
  {
    path: "/kpi",
    name: "KPI",
    icon: TimelineIcon,
    component: KPI,
    layout: "/admin",
    visibility: true
  },
  {
    path: "/signup",
    name: "SignUp",
    icon: FormatAlignJustifyIcon,
    component: SignUp,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/version",
    name: "Version",
    icon: HistoryIcon,
    component: Version,
    layout: "/admin",
    visibility: true
  },
  {
    path: "/newcustomer",
    name: "New Deployment",
    icon: "content_paste",
    component: NewCustomer,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/deploymentwizard",
    name: "Pilot Deployment",
    icon: "content_paste",
    component: DeploymentWizard,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/productiondeployment",
    name: "Production Deployment",
    icon: "content_paste",
    component: ProductionDeployment,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/customerdetail/:rid",
    name: "Deployment Detail",
    icon: "content_paste",
    component: CustomerDetail,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/availabilitydetail/:rid",
    name: "Availability Detail",
    icon: "content_paste",
    component: AvailabilityDetail,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/fulfilmentstatus/:rid",
    name: "Deployment Status",
    icon: "content_paste",
    component: FulfilmentStatus,
    layout: "/admin",
    visibility: false
  },
  {
    path: "/onboarding",
    name: "Onboarding Template",
    icon: "content_paste",
    component: Onboarding,
    layout: "/admin",
    visibility: false
  }
];

if (!Auth.isAdminUser()) {
  dashboardRoutes.splice(1, 1);
}

export default dashboardRoutes;

/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : New Deployment Creation screen
*/
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Select, CardActions } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";


import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import "@pathofdev/react-tag-input/build/index.css";
import ReactTagInput from "@pathofdev/react-tag-input";

import ServerApi from '../../modules/server-api';
import Divider from '@material-ui/core/Divider';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import SidebarHelp from "components/Sidebar/SidebarHelp";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  successIcon: {
    color: 'green',
    height: 40,
    width: 40,
  },
  errorIcon: {
    color: 'red',
    height: 40,
    width: 40,
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  require: {
    color: "rgba(246,87,87)",
    fontSize: "12px"
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: 20
  },
  paper: {
    maxWidth: 600,
    margin: `10px auto`,
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: 10,
    marginLeft: 10,
  },
  bottom: {
    fontSize: "14px",
    marginBottom: "3px",
  }
};


class NewCustomer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customerName: '',
      retailerId: '',
      customerNameError: '',
      retailerIdError: '',
      value: '',
      simulationmode: true,
      tags: ["SKU", "Gender", "Size", "Color", "Department", "Style", "Brand"],
      disabledCrete: false,
      customerState: false,
      deploymentType: 'demo',
      storeSize: '',
      region: '',
      reg: [],
      rids: [],
      loading: false,
      retail: '',
      sidebarOpen: false,
      titleMsg: "",
      version: [],
      vers: ''
    };
  }

  componentDidMount() {
    ServerApi.getUserDepData(this);
  }


  handleSimulationMode = e => {
    this.setState({ simulationmode: e.target.checked });
  }
  handleNameChange = e => {
    this.setState({ customerName: e.target.value },
      () => { this.validateName(); });
  }

  handleRidChange = e => {
    console.log(e.target.value);
    this.setState({ retailerId: e.target.value },
      () => { this.validateId() });
  }

  validateName = () => {
    const { customerName } = this.state;
    this.setState({ customerNameError: customerName.length > 3 ? null : 'Customer Name must have minimum 4 characters' });
  }

  validateId = () => {
    const { retailerId } = this.state;
    this.setState({ retailerIdError: retailerId.length > 4 ? null : 'Retailer Id must have minimum 5 characters' });
  }

  addTags(newTags) {
    this.setState({ tags: newTags });
  }

  handleChange = (e) => {
    this.setState({ region: e.target.value });
  }

  handleVersion = (e) => {
    this.setState({ vers: e.target.value });
  }

  handleCancel = (e) => {
    this.props.history.push("/admin/table");
  }

  handleSubmit = (e) => {

    this.props.history.push("/admin/fulfilmentstatus/" + e.target.elements.retailerId.value);

    e.preventDefault();
    var self = this;

    const customer = {
      name: e.target.elements.customerName.value,
      retail: e.target.elements.retailerId.value,
      type: this.state.deploymentType,
      storeSize: '50',
      status: 'In Progress',
      region: self.state.region,
      version: this.state.vers,
      simulation: self.state.simulationmode,
      customAttributes: self.state.tags
    };

    ServerApi.createDemoDeployment(customer, self);
  }

  handleDrawerToggle = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  onSetSidebarOpen = (event) => {
    this.setState({
      titleMsg: event.currentTarget.id,
      sidebarOpen: true
    });
  }

  render() {
    const { classes } = this.props;
    const { tags, reg, rids, sidebarOpen, titleMsg, version } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <GridContainer>
            <SidebarHelp
              openState={sidebarOpen}
              handleDrawerToggle={this.handleDrawerToggle}
              titleMsg={titleMsg}
            >
            </SidebarHelp>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader style={{ borderTop: "5px solid #4d933c" }}>
                  <Typography>New Demo Deployment
                    <Tooltip title="Demo deployment with preconfigured settings"><HelpIcon fontSize="small" style={{ paddingLeft: "5px", position: 'absolute' }} id="Demo Deployment" onClick={(e) => this.onSetSidebarOpen(e)} /></Tooltip> &nbsp;&nbsp;
                    </Typography>
                </CardHeader>
                <Divider />
                <CardBody>
                  <Grid container>
                    <Grid item xs={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField className={`form-control ${this.state.customerNameError ? 'is-invalid' : ''}`}
                            required
                            id="customerName"
                            name="customerName"
                            label="Customer Name"
                            fullWidth
                            value={this.state.customerName || ''} onChange={this.handleNameChange}
                          />
                          <div className={classes.require}> {this.state.customerNameError} </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Select fullWidth required aria-required="true" id="retailerId" onChange={this.handleRidChange}
                            native
                            input={
                              <OutlinedInput className={classes.listRight} name="retailerId" id="outlined-age-native-simple" />
                            }
                          >
                            <option value="">--Select Retailer Id--</option>
                            {rids.map((x, i) => <option key={i}>{x}</option>)}
                          </Select>
                        </Grid>
                        <Grid item xs={12}>
                          <Select required aria-required="true" id="version_dropdown"
                            native
                            input={
                              <OutlinedInput className={classes.listRight} name="version" id="outlined-age-native-simple2" />
                            }
                            onChange={this.handleVersion}
                          >
                            <option >--Select Version--</option>
                            {version.map((x, i) => <option key={i}>{x}</option>)}
                          </Select>
                          </Grid>
                        <Grid item xs={12}>
                          <p className={classes.bottom}>Product Attributes
                            <Tooltip title="Product attributes fields"><HelpIcon fontSize="small" style={{ paddingLeft: "5px", position: 'absolute' }} id="Product Attribute" onClick={(e) => this.onSetSidebarOpen(e)} /></Tooltip> &nbsp;&nbsp;
                            </p>
                          <ReactTagInput
                            placeholder="Add Custom Attributes ex: Color, Size"
                            maxTags={30}
                            tags={tags}
                            onChange={(newTags) => this.addTags(newTags)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Checkbox
                            checked={this.state.simulationmode}
                            onChange={this.handleSimulationMode}
                            value="simulationmode"
                            color="primary"
                          />
                            Simulation Mode
                     </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.bottom}>Demo Region </p>
                          <Select required aria-required="true" id="dropdown" onChange={this.handleChange}
                            native
                            input={
                              <OutlinedInput className={classes.listRight} name="region" id="outlined-age-native-simple" />
                            }
                          >
                            <option value="">--Select--</option>
                            {reg.map((x, i) => <option key={i}>{x}</option>)}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      Download the latest Halo Android App from here
                      https://install.appcenter.ms/orgs/Checkpt/apps/Halo-App
                    </Grid>
                  </Grid>
                </CardBody>
                <CardActions>
                  <Grid container item xs={12} sm={12} justify="flex-end">
                    <Button type="button" variant="contained" color="secondary" onClick={this.handleCancel}
                      className={classes.button}>
                      Cancel
                     </Button>
                    <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.disabledCrete}>
                      Create
                     </Button>
                  </Grid>
                </CardActions>
              </Card></GridItem></GridContainer>
        </form>
      </div>
    );
  }
}

NewCustomer.propTypes = {
  classes: PropTypes.object
};
export default withStyles(styles)(NewCustomer);

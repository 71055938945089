var url = process.env.REACT_APP_BACKEND_URL;

//Auth
export const POST_LOGIN_URL = url + "/api/auth/login"
export const GET_SESSIONSTATUS = url + "/api/auth/sessionstatus"
export const GET_USER_DETAILS = url + "/api/auth/userdetails"
export const POST_UPDATE_PROFILE = url + "/api/auth/updateprofile"
export const GET_USER_DEP_DATA = url + "/api/auth/getnewdeploymentdata"

//Cloud
export const POST_CLOUD_CREATE_DEMO = url + "/api/cloud/createdemo"
export const GET_DELETE_DEPLOYMENT = url + "/api/cloud/delete"
export const GET_LOG_URL = url + "/api/cloud/logs"
export const GET_UPDATE_VMSTATUS = url + "/api/cloud/togglevm"
export const POST_CREATE_PILOT = url + "/api/cloud/createpilot"
export const GET_VERSION_DATA = url + "/api/cloud/haloversions"

//CustConfig
export const GET_CUST_DASHBOARD = url + "/api/custconfig/dashboard"
export const GET_CUST_DIAGNOSTIC = url + "/api/custconfig/diagnostic"
export const GET_DEP_AVAILABILITY = url + "/api/custconfig/availibility"
export const GET_UPDATE_MONITOR_ALERT = url + "/api/custconfig/togglealert"

//Devices
export const GET_DEVICES = url + "/api/devices"
export const GET_DEVICE_STATUS = url + "/api/devicestatus" //TODO - not used
export const PUT_DEVICE_LAT_LNG = url + "/api/devices/updatelatlng"
export const PUT_DEVICE_STATUS = url + "/api/devices/updatestatus"

//Deployments
export const GET_CUSTOMERS = url + "/api/deployment"
export const GET_CUSTOMER_RETAIL = url + "/api/deployment"
export const GET_TOTAL_DEPLOYMENT = url + "/api/deployment/count"
export const PUT_UPDATE_DEPLOYMENT_ACTIVE = url + "/api/deployment/update"

//Integrations
export const GET_INTEGRATION_DETAILS = url + "/api/integration"
export const POST_INTEGRATION_API = url + "/api/integration"
export const GET_INTEGRATION_TYPE = url + "/api/integration"
export const POST_UPD_PRODUCT_LEVEL = url + "/api/integration/productlevel"
export const POST_HHUI_CONFIG = url + "/api/integration/hhui"
export const GET_PROPERTY = url + "/api/integration/getproperty"
export const GET_DYNAMIC_FILE = url + "/api/integration/dynamicFile"
export const POST_CUSTOM_TABLE_API = url + "/api/integrations/product/customattr"

//Monitor
export const POST_ADD_NEW_MONITORING = url + "/api/monitor/addmonitoring"
export const GET_DELETE_MONITORING = url + "/api/monitor/deletemonitoring"
export const POST_EDIT_MONITORING = url + "/api/monitor/editmonitoring"
export const GET_MONITORING_DETAILS = url + "/api/monitor/getmonitoringdetails"
export const GET_MONITOR_BY_RID = url + "/api/monitor/get"

//Notification
export const GET_NOTIFICATION_ALL = url + "/api/notification/getunread"
export const POST_NOTIFICATION_ID = url + "/api/notification/read"
export const GET_NOTIFICATION_PAGE = url + "/api/notification/pagewise"

//Reports
export const GET_REPORT_DATA = url + "/api/reports/getreport"
export const GET_ALL_REPORTS = url + "/api/reports/getallreports"

//TODO - not used -- need to check and remove
export const POST_UPLOAD_LOCATION = url + "halo/location"
export const POST_UPLOAD_PRODUCT = url + "halo/product"


export const GET_VERSION_URL = url + "/api/version"

//url for DevOps Retailer and enviroment variable
export const GET_RETAILER_URL = url + "/api/portal/retailer"
export const GET_ENV_URL = url + "/api/portal/env"

// post api url 
export const POST_SIGN_UP = url + "/api/auth/register"
export const POST_ZK_SYNC_URL = url + "/api/zookeeper/sync"
export const GET_KPI_URL = url + "/api/kpi"
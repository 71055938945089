/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Dashboard screen will show the availibilty chart and status of each deployment
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import Button from 'components/CustomButtons/Button.jsx';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ServerApi from '../../modules/server-api';

import { NavLink, Link } from "react-router-dom";
import CardActions from '@material-ui/core/CardActions';

import CardHeader from "components/Card/CardHeader.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import Tooltip from '@material-ui/core/Tooltip';
import ChartistGraph from "react-chartist";

import TimelineIcon from '@material-ui/icons/Timeline';
import DetailsIcon from '@material-ui/icons/Details';

import BarChartIcon from '@material-ui/icons/BarChart';

import ReportsTable from "components/Reports/ReportsTable.jsx";
import DialpadIcon from '@material-ui/icons/Dialpad';

import MapView from "components/LeafletMap/MapView.jsx";
import DeviceTableCustom from "components/DeviceTable/DeviceTableCustom.js";

import RoomIcon from '@material-ui/icons/Room';

var Chartist = require("chartist");

const dailySalesChart = {
  data: {
    series: [[24, 24, 24, 24, 24, 24, 24]],
    labels: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"]
  },
  options: {
    lineSmooth: Chartist.Interpolation.simple({
      divisor: 2
    }),

    low: 0,
    high: 24,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  }
};

let closeImg = { cursor: 'pointer', float: 'right', marginTop: '1px', width: '30px' };

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      allreports: [],
      rid: '',
      dailySalesChart: dailySalesChart,
      openAvailibilty: false,
      openReport: false,
      openChart: false,
      openMapView: false,
      uptimedata: {},
      reportdata: []
    };
  }

  componentDidMount() {
    ServerApi.getAllReports(this);
  }

  handleAvailabilityChart = (event, rid) => {
    event.preventDefault();

    //get the data from backend 
    ServerApi.fetchAvailibility(this, rid);
  };

  handleOpenReport = (event, rid) => {
    event.preventDefault();

    //get the data from backend 
    ServerApi.fetchReport(this, rid);
  };

  handleCloseDiagnostic = (e) => {
    this.setState({ open: false, openAvailibilty: false, openReport: false, openChart: false, openMapView: false, openTableView: false });
  };
  handleChart = (e) => {
    this.setState({ open: false, openAvailibilty: false, openReport: false, openChart: true, openMapView: false });
  };

  handleMPOSStatus = (e, rid) => {
    this.setState({ rid: rid, open: false, openAvailibilty: false, openReport: false, openChart: false, openMapView: true, openTableView: false });
  };
  handleMPOSStatusTable = (e, rid) => {
    this.setState({ rid: rid, open: false, openAvailibilty: false, openReport: false, openChart: false, openMapView: false, openTableView: true });
  };
  render() {
    var { allreports, openAvailibilty, openReport, rid, uptimedata, reportdata, openMapView, openTableView } = this.state;
    return (
      <div>
        <GridContainer>
          {allreports.map((prop, key) => {
            return (
              <GridItem xs={12} sm={6} md={3} key={key}>
                <Card>
                  <Button type="button" onClick={event => this.handleOpenReport(event, prop.rid)}>{prop.name}</Button>
                  <CardActions style={{ justifyContent: 'center' }}>
                    {prop.deviceAppInsight ?
                      <React.Fragment>
                        <Link
                          component="button"
                          variant="body2"
                          to="#"
                          onClick={event => this.handleMPOSStatus(event, prop.rid)}>
                          <Tooltip title="MPOS status Map View"><RoomIcon /></Tooltip>
                        </Link>
                        <Link
                          component="button"
                          variant="body2"
                          to="#"
                          onClick={event => this.handleMPOSStatusTable(event, prop.rid)}>
                          <Tooltip title="MPOS status Table View"><DialpadIcon /></Tooltip>
                        </Link>
                      </React.Fragment>
                      : null}
                    <Link
                      component="button"
                      variant="body2"
                      to="#"
                      onClick={event => this.handleAvailabilityChart(event, prop.rid)}>
                      <Tooltip title="Availability Chart for last 7 days - Popup"><TimelineIcon /></Tooltip>
                    </Link>
                    <NavLink
                      color="primary"
                      to={'/admin/availabilitydetail/' + prop.rid}
                    >
                      <Tooltip title="Azure Infrastructure Diagonstic Details - New Page"><DetailsIcon /></Tooltip>
                    </NavLink>
                    {prop.kpilink ?
                      <React.Fragment>
                        <a
                          href={prop.kpilink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Tooltip title="KPI report - Will redirect to Google Datastudio"><BarChartIcon /></Tooltip>
                        </a>
                      </React.Fragment>
                      : null}
                  </CardActions>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>

        <Dialog open={openAvailibilty} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Weekly Availability for {rid} </DialogTitle>
          <DialogContent>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={uptimedata}
                  type="Line"
                  options={dailySalesChart.options}
                />
              </CardHeader>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDiagnostic} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openReport} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="lg">
          <DialogTitle id="form-dialog-title">Reports for {rid} </DialogTitle>
          <DialogContent>
            <Card ReportCard>
              <ReportsTable
                data={reportdata}
              />
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDiagnostic} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapView} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="xl">
          <DialogTitle id="form-dialog-title" onClose={this.handleCloseDiagnostic}>Devices Map View for Retailer :{rid}
            <IconButton onClick={this.handleCloseDiagnostic} style={closeImg}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <MapView data={rid} />
          </DialogContent>
        </Dialog>
        <Dialog open={openTableView} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="lg">
          <DialogTitle id="form-dialog-title" onClose={this.handleCloseDiagnostic}>Devices Table View for Retailer : {rid}
            <IconButton onClick={this.handleCloseDiagnostic} style={closeImg}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DeviceTableCustom data={rid} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Reports);
/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Dashboard screen will show the availibilty chart and status of each deployment
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import Button from 'components/CustomButtons/Button.jsx';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import CardBody from "components/Card/CardBody.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ServerApi from '../../modules/server-api';

import { NavLink } from "react-router-dom";
import CardActions from '@material-ui/core/CardActions';

import Table from "@material-ui/core/Table";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import ReportIcon from '@material-ui/icons/Report';
import Tooltip from '@material-ui/core/Tooltip';

import Moment from 'react-moment';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Fab from '@material-ui/core/Fab';

import "@pathofdev/react-tag-input/build/index.css";
import ReactTagInput from "@pathofdev/react-tag-input";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      deployment: 0,
      deployments: [],
      monitoring: [],
      availabilityDialog: false,
      DeleteDialog: false,
      EditDialog: false,
      name: '',
      nameUpdate: '',
      ridConfirm: '',
      ridConfirm2: '',
      endpoint: '',
      monitorurlUpdate: '',
      interval: '',
      intervalUpdate: '',
      timezoneUpdate: '',
      monitorfrom: '',
      monitortill: '',
      emails: [],
      emailsUpdate: [],
      inputValue: '',
      disabled: false,
      open: false
    };
  }

  componentDidMount() {
    ServerApi.getDashboardData(this);
  }
  handleSave = (e) => {
    this.setState({
      disabled: true,
    });
    ServerApi.addNewMonitoring(this, this.state.name, this.state.rid, this.state.endpoint, this.state.emails, this.state.interval);
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleCloseDiagnostic = (e) => {
    this.setState({ open: false, openAvailibilty: false });
  };
  handleTouchTap = (event, rid) => {
    event.preventDefault();
    ServerApi.fetchMonitoring(this, rid);
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClickDialog(e) {
    this.setState({
      availabilityDialog: true
    });
  }

  handleClickDeleteDialog(e) {
    e.preventDefault();
    this.setState({
      DeleteDialog: true
    });
  }

  handleClickEditDialog = (e, rid) => {
    e.preventDefault();
    ServerApi.getMonitoringDetails(this, rid);
    this.setState({
      EditDialog: true
    });
  }

  handleDeleteRidConfirm = e => {
    this.setState({ ridConfirm: e.target.value });
  }

  handleEditRidConfirm = e => {
    this.setState({ ridConfirm2: e.target.value });
  }

  handleMonitorTill = e => {
    this.setState({ monitortill: e.target.value });
  }

  handleMonitorFrom = e => {
    this.setState({ monitorfrom: e.target.value });
  }

  handleCloseDialog(e) {
    this.setState({
      availabilityDialog: false
    });
  }

  handleCloseDeleteDialog(e) {
    this.setState({
      DeleteDialog: false
    });
  }

  handleCloseEditDialog(e) {
    this.setState({
      EditDialog: false
    });
  }

  handleDeleteMonitoring(e) {
    ServerApi.deleteMonitoring(this, this.state.ridConfirm);
    this.setState({
      DeleteDialog: false
    });
  }

  handleEditMonitoring(e) {
    ServerApi.editMonitoring(this, this.state.nameUpdate, this.state.monitorurlUpdate, this.state.emailsUpdate, this.state.intervalUpdate, this.state.timezoneUpdate,this.state.monitorfrom,this.state.monitortill, this.state.ridConfirm2);
    this.setState({
      EditDialog: false
    });
  }

  handleName(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleNameUpdate(e) {
    this.setState({
      nameUpdate: e.target.value
    });
  }

  handleRid(e) {
    this.setState({
      rid: e.target.value
    });
  }

  handleMonitorURL(e) {
    this.setState({
      endpoint: e.target.value
    });
  }

  handleMonitorURLUpdate(e) {
    this.setState({
      monitorurlUpdate: e.target.value
    });
  }

  handleInterval(e) {
    this.setState({
      interval: e.target.value
    });
  }

  handleIntervalUpdate(e) {
    this.setState({
      intervalUpdate: e.target.value
    });
  }

  handleTimezoneUpdate(e) {
    this.setState({
      timezoneUpdate: e.target.value
    });
  }

  handleEmail(newEmails) {
    this.setState({ emails: newEmails });
  }

  handleEmailUpdate(newEmails) {
    this.setState({ emailsUpdate: newEmails });
  }

  toggleMonitoring = (event, rid) => {
    event.preventDefault();
    ServerApi.setAlertValue(this, rid);
  }


  render() {
    var { deployments, open, monitoring, emails, emailsUpdate } = this.state;
    const { availabilityDialog, DeleteDialog, EditDialog } = this.state;
    return (
      <div>
        <Fab size="small" color="primary" aria-label="add" style={{ position: 'absolute', right: 5, marginTop: -15 }} onClick={(e) => this.handleClickDialog(e)}>
          <AddIcon />
        </Fab>
        <Dialog open={availabilityDialog} onClose={(e) => this.handleCloseDialog(e)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New Monitoring</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="Name"
              label="Name"
              type="text"
              fullWidth
              onChange={(e) => this.handleName(e)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="Rid"
              label="Retailer Id (RID)"
              type="text"
              fullWidth
              onChange={(e) => this.handleRid(e)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="MonitorURL"
              label="Monitoring URL"
              type="text"
              fullWidth
              onChange={(e) => this.handleMonitorURL(e)}
            />
            <br/><br/>
            <ReactTagInput
              placeholder="Add Alert Emails"
              maxTags={30}
              tags={emails}
              onChange={(addEmails) => this.handleEmail(addEmails)}
            />
            <br/>
            <label style={{ fontSize: "110%" }}>Interval</label>&nbsp;&nbsp;&nbsp;
            <Select required aria-required="true" id="dropdown" onChange={(e) => this.handleInterval(e)}
              native
              input={
                <OutlinedInput />
              }
            >
              <option>--Select--</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>30</option>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleCloseDialog(e)} color="primary">
              Cancel
          </Button>
            <Button onClick={(e) => this.handleSave(e)} disabled={this.state.disabled} color="primary">
              Save
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={DeleteDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="delete-dialog-title">Delete This Monitoring </DialogTitle>
          <DialogContent>
            <p style={{ color: "red" }}>&nbsp;&nbsp;&nbsp;&nbsp;This step cannot be reversed
              <ErrorOutline style={{ position: 'absolute', left: 11, marginTop: 0 }} />
            </p>
            <p>Are you Sure?</p>
            <TextField
              required
              id="rid"
              name="rid"
              label="Confirm Retailer Id"
              fullWidth
              onChange={this.handleDeleteRidConfirm}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleCloseDeleteDialog(e)} color="primary">
              No
              </Button>
            <Button onClick={(e) => this.handleDeleteMonitoring(e)} color="primary">
              Yes
              </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={EditDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="delete-dialog-title">Edit Monitoring Details</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              value={this.state.nameUpdate}
              margin="dense"
              id="NameUpdate"
              label="Name"
              type="text"
              fullWidth
              onChange={(e) => this.handleNameUpdate(e)}
            />
            <TextField
              autoFocus
              value={this.state.monitorurlUpdate}
              margin="dense"
              id="MonitorURLUpdate"
              label="Monitoring URL"
              type="text"
              fullWidth
              onChange={(e) => this.handleMonitorURLUpdate(e)}
            />
            <br /><br />
            <ReactTagInput
              value={this.state.emailsUpdate}
              placeholder="Add Alert Emails"
              maxTags={30}
              tags={emailsUpdate}
              onChange={(addEmails) => this.handleEmailUpdate(addEmails)}
            />
            <br />
            <label style={{ fontSize: "110%" }}>Interval</label>&nbsp;&nbsp;&nbsp;
            <Select required aria-required="true" id="dropdown" onChange={(e) => this.handleIntervalUpdate(e)}
              native
              input={
                <OutlinedInput />
              }
              value={this.state.intervalUpdate}
            >
              <option>--Select--</option>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>30</option>
            </Select> &nbsp;&nbsp;&nbsp;&nbsp;
            <label style={{ fontSize: "110%" }}>Timezone</label>&nbsp;&nbsp;&nbsp;
            <Select required aria-required="true" id="dropdown" onChange={(e) => this.handleTimezoneUpdate(e)}
              native
              input={
                <OutlinedInput />
              }
              value={this.state.timezoneUpdate}
            >
              <option>--Select--</option>
              <option>BST</option>
              <option>CDT</option>
              <option>CEST</option>
              <option>CST</option>
              <option>EDT</option>
              <option>GMT</option>
              <option>IST</option>
              <option>GST</option>
            </Select>
            <br /><br />
            <TextField
              required
              value={this.state.monitorfrom}
              id="time1"
              label="Monitor From"
              type="time"
              defaultValue="10:00"
              onChange={this.handleMonitorFrom}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <TextField
              required
              value={this.state.monitortill}
              id="time2"
              label="Monitor Till"
              type="time"
              defaultValue="04:00"
              onChange={this.handleMonitorTill}
            />
            <br/><br/>
            <TextField
              required
              value={this.state.ridConfirm2}
              id="ridConfirm"
              name="ridConfirm"
              label="Confirm Retailer Id"
              fullWidth
              onChange={this.handleEditRidConfirm}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleCloseEditDialog(e)} color="primary">
              Cancel
              </Button>
            <Button onClick={(e) => this.handleEditMonitoring(e)} color="primary">
              Update
              </Button>
          </DialogActions>
        </Dialog>

        <GridContainer>
          {deployments.map((prop, key) => {
            return (
              <GridItem xs={12} sm={6} md={3} key={key}>
                <Card>
                  <Button type="button" color={prop.status} endIcon={prop.incident24Hr ?
                    <Tooltip title="Incidents occured in last 24 hours"><ReportIcon /></Tooltip>
                    : null}
                    onClick={event => this.handleTouchTap(event, prop.rid)}>{prop.name}

                  </Button>
                  <CardActions style={{ justifyContent: 'center' }}>
                    <NavLink
                      color="primary"
                      to='#'
                    >
                      {prop.alert ?
                        <Tooltip title="Turn off the monitoring"><PauseIcon onClick={event => this.toggleMonitoring(event, prop.rid)} /></Tooltip>
                        :
                        <Tooltip title="Turn on the monitoring"><PlayArrowIcon onClick={event => this.toggleMonitoring(event, prop.rid)} /></Tooltip>
                      }
                    </NavLink>
                    <NavLink
                      color="primary"
                      to='#'
                      onClick={(e) => this.handleClickEditDialog(e, prop.rid)}
                    >
                      <Tooltip title="Edit details of monitoring">

                        <EditIcon />

                      </Tooltip>
                    </NavLink>
                    <NavLink
                      color="primary"
                      to='#'
                      onClick={(e) => this.handleClickDeleteDialog(e)}
                    >
                      <Tooltip title="Delete this monitoring">
                        <DeleteForeverIcon />
                      </Tooltip>
                    </NavLink>
                  </CardActions>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
        <CardBody>
          <Dialog open={open} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Latest 50 Incidents </DialogTitle>
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Response Message
                    </TableCell>
                    <TableCell>
                      Status Code
                    </TableCell>
                    <TableCell>
                      Date (YYYY/MM/DD - HH:mm)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monitoring.map((prop, key) => {
                    return (
                      <TableRow>
                        <TableCell id={prop.retail}>
                          {prop.message}
                        </TableCell>
                        <TableCell>
                          {prop.statusCode}
                        </TableCell>
                        <TableCell id={prop.retail}>
                          <Moment format="YYYY/MM/DD - HH:mm">
                            {prop.dateTime}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDiagnostic} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
import React from 'react';
import {Popup} from 'react-leaflet';
import { Divider } from '@material-ui/core';

const MarkerPopupOnline = (props) => {

  const { name, description,locationId,antennasEnabled, statusChange} = props.data;
  return  (<Popup>
    <div className='poup-text'>
      Name - {name} <br></br>
      Description - {description} <br></br>
      Location - {locationId} <br></br>
      Antennas Enabled - {antennasEnabled} <br></br>
      Up since - {statusChange}
    </div>
    <Divider/>
  </Popup>);
};

export default MarkerPopupOnline;
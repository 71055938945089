import React from 'react'
import {Marker} from 'react-leaflet';
import MarkerPopupOffline from './MarkerPopupOffline';
import MarkerPopupOnline from './MarkerPopupOnline';
import L from 'leaflet';
import ServerApi from '../../modules/server-api';

import greenImg from 'assets/img/marker-icon-2x-green.png';
import redImg from 'assets/img/marker-icon-2x-red.png';
import shadowImg from 'assets/img/marker-shadow.png';

export const green = L.icon({
  iconUrl: greenImg,
  shadowUrl: shadowImg,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  className: 'leaflet-venue-icon'
});

export const red = L.icon({
  iconUrl: redImg,
  shadowUrl: shadowImg,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  className: 'leaflet-venue-icon'
});

const VenueMarkers = (props) => {
  const { venues,dragFlag } = props;

  const markers = venues.map((venue, index) => (
    venue.status === "green" ?
     <Marker
      ondragend={event => ServerApi.updateDeviceLatLng(event.target.getLatLng().lat,event.target.getLatLng().lng,venue.name,venue.rid)}
      draggable={dragFlag}
      key={index} position={venue.geometry} icon={green} >
      <MarkerPopupOnline data={venue}/>
    </Marker>
       : 
    <Marker 
      draggable={dragFlag}
      ondragend={event => ServerApi.updateDeviceLatLng(event.target.getLatLng().lat,event.target.getLatLng().lng,venue.name,venue.rid)}
      key={index} position={venue.geometry} icon={red} >
      <MarkerPopupOffline data={venue}/>
    </Marker>
    
  ));

  return markers
};

export default VenueMarkers;
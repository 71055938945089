import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
export default function Spinner() {
  return (
    <div
      style={{
        //display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50%',
        width:'50%',
        opacity:5
      }}
    >
      <CircularProgress color="primary" />
    </div>
  )
}


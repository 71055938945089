/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Support Dashboard screen will show the availibilty chart and status of each deployment
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import Button from 'components/CustomButtons/Button.jsx';
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";

import CardBody from "components/Card/CardBody.jsx";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ServerApi from '../modules/server-api';

import { Link } from "react-router-dom";
import CardActions from '@material-ui/core/CardActions';

import CardHeader from "components/Card/CardHeader.jsx";
import Table from "@material-ui/core/Table";

import ReportIcon from '@material-ui/icons/Report';
import Tooltip from '@material-ui/core/Tooltip';

import Divider from '@material-ui/core/Divider';

import ChartistGraph from "react-chartist";

import Moment from 'react-moment';

import TimelineIcon from '@material-ui/icons/Timeline';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Typography from "@material-ui/core/Typography";

import Auth from '../modules/auth';
import logo from "assets/img/halooneview.png";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

var Chartist = require("chartist");

const dailySalesChart = {
    data:{
      series :[[24,24,24,24,24,24,24]],
      labels:["Sun","Mon","Tue","Wed","Thr","Fri","Sat"]
    },
    options: {
      lineSmooth: Chartist.Interpolation.simple({
        divisor: 2
      }),
     
      low: 0,
      high: 24,
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
  };

const supportStyles = {
    appBar: {
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        zIndex: "1029",
        border: "0",
        color:"#ffffee",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
        backgroundColor: "#2e3338cc"
      }
  }
class Support extends React.Component {

  state = {
    value: 0,
    deployment: 0,
    deployments: [],
    monitoring: [],
    open: false,
    dailySalesChart:dailySalesChart,
    uptimedata:[],
  };
  componentDidMount() {
    ServerApi.getDashboardData(this);
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleCloseDiagnostic = (e) => {
    this.setState({ open: false,openAvailibilty:false });
  };
  handleTouchTap = (event, rid) => {
    event.preventDefault();
    ServerApi.fetchMonitoring(this, rid);
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleLogout = event => {
    Auth.deauthenticateUser();
    window.location.href = "/login/login";
  };

  handleAvailabilityChart = (event, rid) => {
    event.preventDefault();
    
    //get the data from backend 
    ServerApi.fetchAvailibility(this,rid);
  };

  toggleMonitoring = (event, rid) => {
    event.preventDefault();
    ServerApi.setAlertValue(this, rid);
  }

  render() {
    var { deployments, open, monitoring, openAvailibilty,rid,uptimedata} = this.state;
    return (
      <div style={{padding: '60px'}} >
      <AppBar style={supportStyles.appBar}>
      <Toolbar>
        <Hidden smDown implementation="css">
        <Typography onClick={this.handleLogout}> <img src={logo} alt="Halo OneView" style={{height:"20px",marginRight:"15px" }} /> Support User - Logout</Typography>
          
        </Hidden>
      </Toolbar>
    </AppBar>
        <GridContainer className={supportStyles.content}>
          {deployments.map((prop, key) => {
            return (
              <GridItem xs={12} sm={6} md={3} key={key}>
                <Card>
                  <Button type="button" color={prop.status} endIcon={prop.incident24Hr ?
                    <Tooltip title="Incidents occured in last 24 hours"><ReportIcon /></Tooltip>
                    : null}
                    onClick={event => this.handleTouchTap(event, prop.rid)}>{prop.name}</Button>

                  <CardActions style={{ justifyContent: 'center' }}>
                    <Link
                      color="primary"
                      key="1"
                    >
                    {prop.alert ?
                     <Tooltip title="Turn off the monitoring"><PauseIcon  onClick={event => this.toggleMonitoring(event, prop.rid)}/></Tooltip> 
                     :
                     <Tooltip title="Turn on the monitoring"><PlayArrowIcon  onClick={event => this.toggleMonitoring(event, prop.rid)}/></Tooltip>
                     }
                    </Link>
                    <hr/>
                    <Divider orientation="vertical" />
                    <Link
                      component="button"
                      variant="body2"
                      to="#"
                      onClick={event => this.handleAvailabilityChart(event, prop.rid)}>
                      <Tooltip title="Availability Chart for last 7 days"><TimelineIcon/></Tooltip>
                      </Link>
                  </CardActions>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
        <CardBody>
          <Dialog open={open} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Latest 50 Incidents </DialogTitle>
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Response Message 
                    </TableCell>
                    <TableCell>
                      Status Code
                    </TableCell>
                    <TableCell>
                      Date (YYYY/MM/DD - HH:mm)
                          </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {monitoring.map((prop, key) => {
                    return (
                      <TableRow>
                        <TableCell id={prop.retail}>
                        {prop.message}
                        </TableCell>
                        <TableCell>
                        {prop.statusCode}
                        </TableCell>
                        <TableCell id={prop.retail}>
                          <Moment format="YYYY/MM/DD - HH:mm">
                            {prop.dateTime}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDiagnostic} color="primary">
                Cancel
                    </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={openAvailibilty} onClose={this.handleCloseDiagnostic} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Weekly Availability for {rid} </DialogTitle>
            <DialogContent>
            <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={uptimedata}
                type="Line"
                options={dailySalesChart.options}
              />
            </CardHeader>
          </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDiagnostic} color="primary">
                Cancel
                    </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </div>
    );
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(supportStyles)(Support);

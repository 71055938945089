
import { FormControl, FormLabel, Grid, IconButton, makeStyles, Paper, Switch, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import OneViewAlert from 'components/OneViewAlert/OneViewAlert';
import RetailerAutoComplete from 'components/RetailerAutoComplete/RetailerAutoComplete';
import IframeResizer from 'iframe-resizer-react';
import { ApiResponseType } from 'interfaces/IApiResponseType';
import { IRetailerModel } from 'interfaces/IRetailerModel';
import { getKpiData } from 'modules/serverApi';
import React, { useEffect, useState } from 'react';
import EditIcon from "@material-ui/icons/Edit";
import { KpiReportType } from 'interfaces/KpiReportType';
import KpiEditcomponent from 'components/KpiComponents/KpiEditcomponent';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormControl-root": {
      width: "95%",
      margin: theme.spacing(1)
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    alignContent: "center",
    color: theme.palette.text.secondary
  },
  grid: {
    alignItems: "center",
    justifyContent: "center"
  },
  formControl: {
    alignItems: "center",
    textAlign: "center",
    alignContent: "center"
  }
}));


function Kpi() {

  const [alertResp, setAlertResp] = useState<ApiResponseType | undefined>();
  const [kpiData, setKpiData] = useState<IRetailerModel[] | undefined>();
  const [selectedRetailer, setSelectedRetailer] = useState<IRetailerModel | null>();
  const [selectedReport, setSelectedReport] = useState<KpiReportType | null>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(true);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showAllRetailers, setShowAllRetailers] = useState<boolean>(false);

  useEffect(() => {
    updateKpiData()
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("showAllRetailers : ", showAllRetailers)
    updateKpiData()
    setSelectedReport(null)
  }, [showAllRetailers]);  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    console.log("selectedRetailer changed")
    setSelectedReport(null)
  }, [selectedRetailer]);

  const updateKpiData = () => {
    getKpiData().then(data => {
      console.log("kpi data : ", data)
      if (showAllRetailers) {
        setKpiData(data)
      } else {
        let filteredData: IRetailerModel[] = []
        data.forEach(ele => {
          if (ele.kpiReportModelList && ele.kpiReportModelList.length > 0) {
            filteredData.push(ele);
          }
        })
        setKpiData(filteredData)
      }
    }).catch(err => {
      const alert: ApiResponseType = {
        message: err.message,
        status: 'error'
      }
      setAlertResp(alert);
    });
  }


  function handleRetailerInputChange(value: IRetailerModel | null) {
    if (value == null) {
      setDisableEdit(true)
      return;
    }
    console.log("handleRetailerInputChange : ", value)
    setSelectedRetailer(value);
    setDisableEdit(false)
  }

  function handleReportchange(value: any) {
    console.log("handleReportchange : ", value)
    setSelectedReport(value)
  }

  function handleEditButtonClick() {
    console.log("edit button clicked")
    setShowEditDialog(true)
  }

  function closeEditDialog() {
    setDisableEdit(true)
    setShowEditDialog(false)
    setSelectedRetailer(null)
    setSelectedReport(null)
    updateKpiData()
  }

  const handleShowAllRetailers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllRetailers(event.target.checked)
  }
  function getReportOptions(): KpiReportType[] {
    if (selectedRetailer?.kpiReportModelList == null) {
      return []
    }
    return selectedRetailer?.kpiReportModelList
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <OneViewAlert alertProps={alertResp} />
      <KpiEditcomponent selectedRetailer={selectedRetailer} showDialog={showEditDialog} closeDialog={closeEditDialog} alertPopup={setAlertResp} />

      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <RetailerAutoComplete retailerData={kpiData} onchange={handleRetailerInputChange} />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <Autocomplete
              className={classes.root}
              options={getReportOptions()}
              getOptionLabel={(option) => option?.rname}
              value={selectedReport}
              renderInput={(params) => <TextField {...params} label="Select report" variant="outlined" />}
              onChange={(event: any, value: KpiReportType | null) => {
                handleReportchange(value);
              }}
            />
          </Paper>
        </Grid>
        <Grid container item xs={1} className={classes.grid}>
          <IconButton disabled={disableEdit} onClick={handleEditButtonClick} color="primary">
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid container item xs={1} className={classes.grid}>
          <FormControl className={classes.formControl}>
            <FormLabel>Show All Retailers</FormLabel>
            <Switch
              checked={showAllRetailers}
              onChange={handleShowAllRetailers}
              name="checkedB"
              color="primary"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        {/* <iframe width="800" height="800" src={selectedRetailer?.url}></iframe> */}
        <IframeResizer
          log
          inPageLinks
          heightCalculationMethod="grow"
          src={selectedReport?.rurl}
          style={{ width: '1px', minWidth: '100%', height: '750px', minHeight: '100%', border: '0px' }}
        />
      </Grid>
    </div>
  )
}

export default Kpi;
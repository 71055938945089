import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { IRetailerModel } from "../../interfaces/IRetailerModel";
import { IEnvironmentModel } from "../../interfaces/IEnvironmentModel";
import { getEnvironments, getRetailers, syncZookeeper } from "../../modules/serverApi";
import { ZKSyncType } from "interfaces/IZKSyncType";
import Button from "components/CustomButtons/Button";
import { ApiResponseType } from "interfaces/IApiResponseType";
import OneViewAlert from "components/OneViewAlert/OneViewAlert";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormControl-root": {
      width: "95%",
      margin: theme.spacing(1)
    },
  },
  grid: {
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    alignContent: "center",
    color: theme.palette.text.secondary
  }
}));


function Zookeeper() {
  //Hooks
  const [AlertResp, setAlertResp] = useState<ApiResponseType>();
  const [environments, setEnvironments] = useState<IEnvironmentModel[] | undefined>();
  const [retailers, setRetailers] = useState<IRetailerModel[]>();
  const [selectedEnvType, setSelectedEnvType] = useState<IEnvironmentModel | undefined>();
  const [syncButtonDisabled, setSyncButtonDisabled] = useState(true);
  const [selectedRetailer, setSelectedRetailer] = useState<IRetailerModel>()

  useEffect(() => {
    getEnvironments().then(resp => {
      setEnvironments(resp);
    });
  }, []);

  useEffect(() => {
    const currentRetailer = selectedEnvType?.environmentType;
    if (!currentRetailer) {
      return
    }
    getRetailers(currentRetailer).then(resp => {
      setRetailers(resp);
    });
  }, [selectedEnvType]);

  function handleEnvInputChange(value: any) {
    const setVal: IEnvironmentModel = {
      environmentType: value
    };
    setSyncButtonDisabled(true);
    setSelectedEnvType(setVal);
  }

  function handleRetailerInputChange(value: any) {
    console.log("handleRetailerInputChange : ", value)
    setSyncButtonDisabled(false);
    setSelectedRetailer(value);
  }

  async function syncButtonClick() {
    if (!selectedRetailer) {
      return
    }
    const zkSync: ZKSyncType = {
      environmentType: selectedRetailer.environmentType,
      rid: selectedRetailer.rid
    }
    console.log("sync : ", zkSync)
    syncZookeeper(zkSync).then(resp => {
      console.log("sync resp ", resp)
      if (resp) {
        const aresp: ApiResponseType = {
          message: resp?.message || "",
          status: resp?.status || "info"
        }
        setAlertResp(aresp)
      }
    })
  }


  const classes = useStyles();
  return (
    <div className={classes.root}>
      <OneViewAlert alertProps={AlertResp} />
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            {
              <Autocomplete
                className={classes.root}
                options={environments}
                onInputChange={(event, newInputValue) => {
                  handleEnvInputChange(newInputValue);
                }}
                getOptionLabel={option => `${option.environmentType}`}
                // defaultValue={selectedEnvType}
                renderInput={params => (
                  <TextField {...params} label="Env" variant="outlined" />
                )}
              />
            }
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            {
              <Autocomplete
                className={classes.root}
                options={retailers}
                getOptionLabel={option => option.retailerName}
                onChange={(event: any, value: IRetailerModel | null) => {
                  handleRetailerInputChange(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label=" Retailer Name"
                    variant="outlined"
                  />
                )}
              />
            }
          </Paper>
        </Grid>
        <Grid container item xs={2} className={classes.grid}>
          <Paper className={classes.paper}>
            <Button color="primary" disabled={syncButtonDisabled} onClick={() => syncButtonClick()}>Sync</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
export default Zookeeper;

/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Deployment list screen for all the customers 
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Typography from '@material-ui/core/Typography';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { NavLink } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import ServerApi from '../../modules/server-api';
import Auth from "modules/auth";

import Divider from '@material-ui/core/Divider';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import TextField from '@material-ui/core/TextField';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  settingIcon: {
    color: 'blue',
    height: 15,
    width: 15,
  },
  successIcon: {
    color: 'green'
  },
  errorIcon: {
    color: 'red'
  },
  colWidth: {
    width: 3
  }
};

class TableList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customers: [],
      logs: [],
      openLog: false,
      popoveropen: false,
      file: '',
      selectedIndex: 1,
      locationupload: false,
      openDelete: false,
      anchorEl: ''
    }
  }
  componentDidMount() {
    if (Auth.isUserAuthenticated()) {
      ServerApi.getCustomers(this);
    }
  }
  handleClick = () => {
    this.props.history.push("/admin/newcustomer");
  }

  handleClick2 = () => {
    this.props.history.push("/admin/deploymentwizard");
  }
  
  handleClick3 = () => {
    this.props.history.push("/admin/productiondeployment");
  }

  handlePopClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handlePopClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleClose = (e) => {
    this.setState({ openLog: false, openDelete: false });
  };

  handleFileClose = (e) => {
    this.setState({ locationupload: false });
  };


  handleTouchTap = (event, statusType) => {
    event.preventDefault();
    this.setState({ openLog: true });

    ServerApi.fetchLog(this, statusType, event.currentTarget.id);
  };

  handleRequestClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  handleDeletePopup = (event) => {
    this.setState({
      openDelete: true,
      rid: event.currentTarget.id
    });
  }

  handleMakeActive = (event) => {
    ServerApi.updateDeployment(this, event.currentTarget.id);
  }

  handleVMActive = (event) => {
    ServerApi.updateVMStatus(this, event.currentTarget.id);
  }

  handleDelete = (event) => {
    event.preventDefault();
    if (this.state.ridConfirm === this.state.rid) {
      this.setState({
        openDelete: false
      });
      ServerApi.deleteDeployment(this, this.state.rid);
    } else {
      this.setState({
        deleteReConfirmMsg: "Retail Id doesn't match"
      });
    }
  };

  handleDeleteRidConfirm = e => {
    this.setState({ ridConfirm: e.target.value });
  }


  render() {
    var { customers, openLog, logs, openDelete } = this.state;
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader style={{ borderTop: "5px solid #1e88e5" }}>
                <Typography>List of Deployments
              <Fab size="small" color="primary" aria-controls="simple-menu" aria-haspopup="true" aria-label="add" className={classes.fab} style={{ position: 'absolute', right: 0, marginTop: -20 }} onClick={event => this.handlePopClick(event)}>
                    <AddIcon />
                  </Fab>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handlePopClose}
                  >
                    <MenuItem onClick={this.handleClick}>Demo</MenuItem>
                    <MenuItem onClick={this.handleClick2}>Pilot</MenuItem>
                    <MenuItem onClick={this.handleClick3}>Production</MenuItem>
                  </Menu>
                </Typography>
              </CardHeader>
              <Divider />
              <CardBody>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key="CustomerName">
                        Deployment Name
                        </TableCell>
                      <TableCell>
                        Retailer Id
                      </TableCell>
                      <TableCell>
                        Type
                      </TableCell>
                      <TableCell>
                        Status
                      </TableCell>
                      <TableCell>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((prop, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>
                            <Typography>
                              {prop.status === "Active" ?
                                <NavLink
                                  to={'/admin/customerdetail/' + prop.retail}
                                  key="1">
                                  {prop.name}
                                </NavLink> : prop.name
                              }
                              &nbsp;<MenuIcon className={classes.settingIcon} id={prop.retail} onClick={event => this.handleTouchTap(event, prop.status)} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {prop.retail}
                          </TableCell>
                          <TableCell>
                            {prop.type}
                          </TableCell>
                          <TableCell>
                            {prop.status}
                          </TableCell>
                          <TableCell>
                            <Grid container alignItems="center">
                              {prop.status === "In Progress" && prop.owner === Auth.getLoggedInUser() ?
                                <Tooltip title="update to Active" style={{ borderRight: '0.05em solid rgba(224, 224, 224, 1)' }}><AirplanemodeActiveIcon id={prop.retail} onClick={event => this.handleMakeActive(event)} />
                                </Tooltip>
                                : null
                              }
                              {prop.status === "Active" ?
                                prop.metadata.infraInfo.vmStatus === 1 ?
                                  <Tooltip title="Shutdown VM" style={{ borderRight: '0.05em solid rgba(224, 224, 224, 1)' }}>
                                    <PowerSettingsNewIcon id={prop.retail} onClick={event => this.handleVMActive(event)} />
                                  </Tooltip>
                                  :
                                  <Tooltip title="StartUp VM" style={{ borderRight: '0.05em solid rgba(224, 224, 224, 1)' }}>
                                    <PlayCircleFilledWhiteIcon id={prop.retail} onClick={event => this.handleVMActive(event)} />
                                  </Tooltip>
                                : null
                              }
                              {prop.owner === Auth.getLoggedInUser() ?
                                <Tooltip title="Delete demo environment"><DeleteForeverIcon id={prop.retail} onClick={event => this.handleDeletePopup(event)} /></Tooltip>
                                : null}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
              <CardBody>
                <Dialog open={openLog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Deployment Steps</DialogTitle>
                  <DialogContent>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Step
                          </TableCell>
                          <TableCell>
                            Description
                          </TableCell>
                          <TableCell>
                            Status
                          </TableCell>
                          <TableCell>
                            &nbsp;
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {logs.map((prop, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell id={prop.retail}>
                                {prop.step}
                              </TableCell>
                              <TableCell id={prop.retail}>
                                {prop.notes}
                              </TableCell>
                              <TableCell id={prop.retail}>
                                {prop.status}
                              </TableCell>
                              <TableCell>
                                {prop.status === "Error" ?
                                  <HighlightOffIcon className={classes.errorIcon} />
                                  :
                                  <CheckCircleOutlineIcon className={classes.successIcon} />
                                }
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openDelete} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Delete "{this.state.rid}" Deployment</DialogTitle>
                  <DialogContent>
                    <Typography color="secondary">
                      This can not be reversed
                      </Typography>
                    <ErrorOutlineOutlinedIcon style={{ fontSize: 70, marginLeft: 50 }} color="secondary" />
                    <TextField
                      required
                      id="rid"
                      name="rid"
                      label="Confirm Retailer Id"
                      fullWidth
                      onChange={this.handleDeleteRidConfirm}
                    />
                    <Typography color="secondary">
                      {this.state.deleteReConfirmMsg}
                    </Typography>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                      No
                    </Button>
                    <Button onClick={event => this.handleDelete(event)} color="secondary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TableList.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(TableList);

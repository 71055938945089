/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch,Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.jsx";

import Login from "layouts/Login.jsx";

import Support from "layouts/Support.jsx";
import Device from "layouts/Device.jsx";

import PrivateRoute from "helpers/private-route.js";

import "assets/css/material-dashboard-react.css?v=1.7.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/device" component={Device} />
      <PrivateRoute path="/admin" component={Admin} />
      <Redirect from="/" to="/admin" />
    </Switch>
  </Router>
  ,
  document.getElementById("root")
);

import React, { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ApiResponseType } from 'interfaces/IApiResponseType';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface AlertDetails {
    alertProps: ApiResponseType | undefined;
}

const OneViewAlert: React.FC<AlertDetails> = ({ alertProps }) => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (alertProps) {
            setOpen(true);
        } else {
            setOpen(false);
        }

    }, [alertProps]);

    return (
        <div>
            {alertProps?.message !== '' ? (
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertProps?.status}>
                        {alertProps?.message.toString()}
                    </Alert>
                </Snackbar>
            ) : (
                ''
            )}
        </div>
    );
}

export default OneViewAlert
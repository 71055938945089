import { Card, CardContent, CardHeader, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as VersionParam from 'constants/version';
import { IVersionBindModel } from 'interfaces/IVersionBindModel';
import { IVersionModel } from 'interfaces/IVersionModel';
import { getMsVersion } from 'modules/serverApi';

function Version() {

    const [versionDetails, setVersionDetails] = useState<IVersionBindModel[]>();

    useEffect(() => {
        getVersions().then(res => {
            setVersionDetails(res);
        });
    }, [])

    async function getVersions() {
        const versions: IVersionBindModel[] = [];
        const msVersion = await getMsVersion();
        versions.push(msVersion)

        let verui: IVersionModel = {
            buildDate: VersionParam.BUILD_DATE,
            buildTimestamp: VersionParam.BUILD_TIMESTAMP,
            version: VersionParam.VERSION
        }

        versions.push({
            moduleName: "OneView-UI",
            versionDetails: verui
        })
        return versions;
    }
    return (
        <div>
            <Grid container justify="center" spacing={3}>
                {
                    versionDetails?.map((item, i) => {
                        return (
                            <Grid item xs key={i}>
                                <Paper>
                                    <Card>
                                        <CardHeader title={item.moduleName} />
                                        <CardContent>
                                            Version : {item.versionDetails.version}
                                        </CardContent>
                                        <CardContent>
                                            BuildDate : {item.versionDetails.buildDate}
                                        </CardContent>
                                        <CardContent>
                                            BuildTimestamp : {item.versionDetails.buildTimestamp}
                                        </CardContent>
                                    </Card>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    );
}

export default Version;
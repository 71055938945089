import { GET_ENV_URL, GET_VERSION_URL, POST_SIGN_UP, POST_ZK_SYNC_URL, GET_RETAILER_URL, GET_KPI_URL } from "../constants/url";
import { IVersionBindModel } from "../interfaces/IVersionBindModel";
import { IVersionModel } from "../interfaces/IVersionModel";
import { IRetailerModel } from "../interfaces/IRetailerModel";
import { IEnvironmentModel } from "../interfaces/IEnvironmentModel";
import { RegisterUserType } from "interfaces/IRegisterUserType";
import axios from 'axios';
import Auth from "./auth";
import { ApiResponseType } from "interfaces/IApiResponseType";
import { ZKSyncType } from "interfaces/IZKSyncType";
import { KpiReportType } from "interfaces/KpiReportType";


const DEFAULT_SUCCESS: ApiResponseType = {
	status: "success",
	message: "SUCCESS"
}

export async function getMsVersion(): Promise<IVersionBindModel> {

	const msVersion = await fetch(GET_VERSION_URL)
		.then(resp => resp.json())
		.then((resp: IVersionModel) => {
			return resp;
		})
		.catch(function (err) {
			throw err;
		})

	const retObj: IVersionBindModel = {
		moduleName: "OneView-MS",
		versionDetails: msVersion
	}

	return retObj;
}
export async function getRetailers(envtype: string): Promise<IRetailerModel[]> {
	return fetch(GET_RETAILER_URL + "?envType=" + envtype)
		.then(resp => resp.json())
		.then((json: IRetailerModel[]) => {
			return json;
		})

}
export async function getEnvironments(): Promise<IEnvironmentModel[]> {
	return fetch(GET_ENV_URL)
		.then(resp => resp.json())
		.then((json: IEnvironmentModel[]) => {
			return json;
		})

}

export async function createUser(userDetails: RegisterUserType): Promise<ApiResponseType | undefined> {
	return genericPost(POST_SIGN_UP, userDetails)
}

export async function syncZookeeper(zkSync: ZKSyncType): Promise<ApiResponseType | undefined> {
	return genericPost(POST_ZK_SYNC_URL, zkSync)
}

export async function getKpiData(): Promise<IRetailerModel[]> {
	return axios.get(GET_KPI_URL, getAuthTokenHeader())
		.then(resp => resp.data)
		.then((json: IRetailerModel[]) => {
			return json;
		})
}

export async function postKpiData(rid: string, data: KpiReportType[]): Promise<ApiResponseType | undefined> {
	const postRidUrl = GET_KPI_URL + "/" + rid
	return axios.post(postRidUrl, data, getAuthTokenHeader())
		.then(() => {
			return DEFAULT_SUCCESS;
		})
		.catch(error => {
			return parseError(error)
		});
}


function genericPost(url: string, data: any): Promise<ApiResponseType | undefined> {
	return axios.post(url, data, getAuthTokenHeader())
		.then(resp => {
			const respObj: ApiResponseType = {
				status: "success",
				message: resp.data
			}
			return respObj;
		}).catch(error => {
			return parseError(error)
		})
}


function parseError(error: any) {
	if (error.response) {
		const respObjexc: ApiResponseType = {
			status: "error",
			message: error.response.data.message
		}
		return respObjexc
	}
	throw error;
}

function getAuthTokenHeader() {
	return {
		headers: {
			Authorization: 'Bearer ' + Auth.getToken(),
		},
	};
}

import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { RegisterUserType } from "interfaces/IRegisterUserType";
import { createUser } from "modules/serverApi";
import { Avatar, Grid, Paper, TextField, Button, Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ApiResponseType } from "interfaces/IApiResponseType";

//setting up Aleart to work
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

//Main functionality 
function SignUp() {
  const classes = useStyles();
  const [showAlert, setShowAlert] = React.useState(false);
  const [ApiResp, setApiResp] = useState<ApiResponseType>();

  const [{ fullName, email, password }, setCred] = useState({
    fullName: "",
    password: "",
    email: "",
  });


  useEffect(() => {
    if (ApiResp) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [ApiResp])



  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  //singup 
  async function getSignUp(fullName: string,
    password: string,
    email: string,
  ) {
    console.log(fullName, email, password);
    const regUser: RegisterUserType = {
      email: email,
      fullname: fullName,
      password: password,
      roles: ["ADMIN"]
    }
    console.log(regUser);

    const resp = await createUser(regUser);
    console.log('resp : ', resp)
    setApiResp(resp);

  }

  const subbtn = (event: React.FormEvent) => {
    event.preventDefault();
    getSignUp(fullName, email, password)
  }
  const avatarStyle = { backgroundColor: "#52C1DA" };
  const btnstyle = { margin: "8px 0" };
  const paperStyle = {
    padding: 20,
    height: "90vh",
    width: 280,
    margin: "40px auto",
  };

  return (
    <div className={classes.root}>
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={ApiResp?.status}>
          {ApiResp?.message}
        </Alert>
      </Snackbar>
      <form onSubmit={subbtn}>
        <Grid >
          <Paper elevation={100} style={paperStyle}>
            <Grid >
              <Avatar style={avatarStyle}>
                <EditIcon />
              </Avatar>
              <h2>Sign Up</h2>

            </Grid>
            <label htmlFor="fullName">fullName</label>
            <TextField variant="outlined" placeholder="fullName" fullWidth value={fullName} required onChange={(event) => setCred({
              fullName: event.target.value,
              password,
              email,
            })} />
            <label htmlFor="email">Email</label>
            <TextField variant="outlined" placeholder="email" value={email} fullWidth required onChange={(event) => setCred({
              fullName,
              password,
              email: event.target.value,
            })} />

            <label htmlFor="password">password</label>
            <TextField variant="outlined" placeholder="password" value={password} type="password" fullWidth required onChange={(event) => setCred({
              fullName,
              password: event.target.value,
              email,
            })} />
            <br />
            <Button

              type="submit"
              color="primary"
              variant="contained"
              style={btnstyle}
              fullWidth
            >Sign Up</Button>

          </Paper>
        </Grid>
      </form>

    </div>
  )

}
export default SignUp;

/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Device Dashboard screen based on the customer
*/

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Divider from '@material-ui/core/Divider';

import MapView from "components/LeafletMap/MapView.jsx";
import DeviceTableCustom from "components/DeviceTable/DeviceTableCustom.js";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// @material-ui/icons
import Typography from "@material-ui/core/Typography";

import Auth from '../modules/auth';
import logo from "assets/img/halooneview.png";

import { Select,MenuItem } from '@material-ui/core';

import Login from "layouts/Login.jsx";



const supportStyles = {
    appBar: {
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        zIndex: "1029",
        border: "0",
        color:"#ffffee",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
        backgroundColor: "#7A7A7A"
      },
      content:{
        flexGrow: 1,
        overflow: 'hidden',
        padding: 20
      }
  }

class Device extends React.Component {

  state = {
    value: 0,
    deployment: 0,
    deployments: [],
    monitoring: [],
    open: false,
    uptimedata:[],
    view:"map",
    rid:"cna",
    userauth:false
  };
  componentDidMount() {
    if(Auth.isDeviceUser()){
      //user is authenticated
      this.setState({userauth:true});
      //get all the retailer for the authenticated user 

    }
  }
  selectView = (event) => {
    this.setState({view:event.target.value});
  };
  selectRetailer = (event) => {
    this.setState({rid:event.target.value});
  };

  handleLogout = event => {
    Auth.deauthenticateUser();
    window.location.href = "/device";
  };

  render() {
    var { rid ,view,userauth} = this.state;
    if (userauth){
    return (
      <div style={{padding: '60px'}}>
      <AppBar style={supportStyles.appBar}>
      <Toolbar>
        <MenuItem>
        <Typography> <img src={logo} alt="Halo OneView" style={{height:"20px",marginRight:"15px" }} />
        </Typography>
        </MenuItem>
        <MenuItem>
       <Typography>
         Select Retailer (Customer)</Typography>
       </MenuItem>
       <MenuItem>        
        <Select
          labelId="displayComp-label"
          value={rid}
          onChange={event => this.selectRetailer(event)}
          inputProps={{
            name: 'displayComp',
            id: 'displayComp',
          }}
        >
          <MenuItem value="canda">CNA </MenuItem>
          <MenuItem value="candaints">CNA INTS</MenuItem>
          <MenuItem value="cnaort">CNA ORT</MenuItem>
        </Select>
        </MenuItem>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
        <MenuItem>
       <Typography>
         Choose View </Typography>
       </MenuItem>
        <MenuItem>
        <Select
          labelId="displayComp-label"
          value={view}
          onChange={event => this.selectView(event)}
        >
          <MenuItem value="map">Map View </MenuItem>
          <MenuItem value="table">Table View </MenuItem>
        </Select>
        </MenuItem>
       <MenuItem>
       <Typography onClick={this.handleLogout} >
         Logout</Typography>
       </MenuItem>
      </div>
      </Toolbar>
    </AppBar>
    {view === "map" ?
        <GridContainer className={supportStyles.content}>
            <MapView data={rid}/>
        </GridContainer>
    :
        <GridContainer className={supportStyles.content}>
            <DeviceTableCustom data={rid}/>
        </GridContainer>
    }
      </div>
     );
    }else{
      return(
      <div>
         <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
             <Card>
                <CardHeader style={{ borderTop: "10px solid #80ccc4" }}>
                  <Typography>
                    Login for Device Monitoring in Halo OneView 
                  </Typography>
                </CardHeader>
                <Divider />
                <CardBody>
                  </CardBody>
                  </Card>
                  </GridItem>
          </GridContainer>
        <Login></Login>
      </div>
      )
    }
  }
}

Device.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(supportStyles)(Device);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { IRetailerModel } from "interfaces/IRetailerModel";
import { KpiReportType } from "interfaces/KpiReportType";
import React, { useEffect, useState } from 'react';
import { postKpiData } from "modules/serverApi";
import { ApiResponseType } from "interfaces/IApiResponseType";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';

interface IRetailerAutoComplete {
    selectedRetailer: IRetailerModel | null | undefined,
    showDialog: boolean,
    closeDialog: (arg: any) => void,
    alertPopup: (arg: ApiResponseType | undefined) => void
}

const emptyReportData: KpiReportType = {
    rname: "",
    rurl: ""
}

const KpiEditcomponent: React.FC<IRetailerAutoComplete> = ({ selectedRetailer, showDialog, closeDialog, alertPopup }) => {

    const [reports, setReports] = useState<KpiReportType[]>([]);
    const [rid, setRid] = useState("");

    useEffect(() => {
        console.log("kpi component refreshed")
        if (!selectedRetailer) {
            return;
        }

        let reportsCopy: KpiReportType[] = [];

        setRid(selectedRetailer?.rid)

        if (!selectedRetailer?.kpiReportModelList || selectedRetailer?.kpiReportModelList?.length === 0) {
            console.log("adding default entry")
            reportsCopy.push({ ...emptyReportData })
        } else {
            selectedRetailer?.kpiReportModelList?.map(report => {
                console.log("adding report : ", report)
                reportsCopy.push(report)
            });
        }
        setReports(reportsCopy);
    }, [selectedRetailer]);


    function postData() {
        console.log("rid : ", rid, " post data : ", reports)
        postKpiData(rid, reports)
            .then(resp => {
                alertPopup(resp);
            }).catch(err => {
                console.log("err : ", err)
                alertPopup({
                    status: "error",
                    message: err.message
                });
            })
    }

    function addNew() {
        setReports([...reports, { ...emptyReportData }])
    }

    function deleteReportRow(index: number) {
        let reportsCopy = [...reports];
        reportsCopy.splice(index, 1);
        setReports(reportsCopy);
    }

    function onChangeReportName(rname: string, index: number) {
        let reportsCopy = [...reports];
        reportsCopy[index].rname = rname;
        setReports(reportsCopy);
    }

    function onChangeReportUrl(rname: string, index: number) {
        let reportsCopy = [...reports];
        reportsCopy[index].rurl = rname;
        setReports(reportsCopy);
    }

    return (
        <div>
            <Dialog open={showDialog} maxWidth="lg">
                <DialogTitle>Edit Kpi Report</DialogTitle>
                <DialogContent>
                    {
                        reports.map((report, i) => {
                            return (
                                <div key={i}>
                                    <form noValidate autoComplete="off">
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <TextField variant="outlined" placeholder="Report Name" value={report.rname} required
                                                    onChange={(event) => onChangeReportName(event.target.value, i)} />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField style={{ width: '500px' }} variant="outlined" placeholder="Report Url" value={report.rurl} required
                                                    onChange={(event) => onChangeReportUrl(event.target.value, i)} />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton aria-label="add" onClick={(e) => deleteReportRow(i)}>
                                                    <RemoveIcon fontSize="large" color="secondary" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            )
                        })
                    }
                    <IconButton aria-label="add" onClick={addNew} color="primary">
                        <AddCircleIcon fontSize="large" />
                    </IconButton>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => closeDialog(e)} color="secondary">
                        Close
                    </Button>
                    <Button onClick={postData} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default KpiEditcomponent

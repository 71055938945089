/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S 
@company : Checkpoint Systems

@description : Availability screen will show data from azure web hook 
              url created by Gangaraju
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import CustomTable from "components/CustomTable/CustomTable.js";

import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ServerApi from '../../modules/server-api';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

class AvailabilityDetail extends React.Component {

  state = {
    currentRetail: '',
    diagnostics: [],
    isVisible: false,
    isLoading:true
  };

  componentDidMount() {
    const { rid } = this.props.match.params;
    this.setState({ currentRetail: rid });
    ServerApi.fetchDiagonstic(this, rid);
  }

  handleShowHide(e, section, key) {
    //alert(section+":"+key);
    if (this.state.isVisible) {
      this.setState({
        isVisible: false
      });
    } else {
      this.setState({
        isVisible: true
      });
    }
  }
  render() {
    const { classes } = this.props;
    var { diagnostics, isVisible } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            Retailer Id :  {this.state.currentRetail}
          </GridItem>
          </GridContainer>

          {this.state.isLoading ?
           <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <Typography>Loading data... </Typography>
                <LinearProgress />
              </Card>
            </GridItem>
            </GridContainer>
            : null}
        <GridContainer>
          {diagnostics.map((prop, key) => {
            return (
              <GridItem xs={12} sm={12} md={12} key={key}>
                <Card key={key}>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}> {prop.infrastructure}</h4>
                  </CardHeader>
                  <Table>
                    <TableBody>
                      {prop.infrastructure_diagnostics.map((prop1, key1) => {
                        return (
                          <TableRow key={key1}>
                            <TableCell>
                              {prop1.heading}
                              <IconButton
                                aria-label="show more"
                                onClick={event => this.handleShowHide(event, prop.infrastructure, key)}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                              {isVisible && <CustomTable
                                data={prop1.details}
                              />}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Card>
              </GridItem>
            );
          })}

        </GridContainer>
      </div>
    );
  }
}

AvailabilityDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(AvailabilityDetail);

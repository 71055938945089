import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';

import productHLogo from "assets/img/product_hierarchy.png";
import displayattr from "assets/img/Display.jpg";
import filterattr from "assets/img/Filter.jpg";
import scanattr from "assets/img/Scan.jpg";

import productsoh from "assets/img/soh.PNG";
import location from "assets/img/location.PNG";
import userdetails from "assets/img/user_details.PNG";

const SidebarHelp = ({ ...props }) => { 

var ProductLevel="";

if(props.titleMsg === "Product Hierarchy"){
    ProductLevel = (
        <div>
            <div>
                <p>1. Product Levels are shown in Portal as below  </p>
              <img src={productHLogo} alt="logo"/>
            </div>
        </div>
    );

}else if(props.titleMsg === "HHUI Config"){
  ProductLevel = (
    <div>
        <div>
          <p>1. Display Attribute as show in handheld device  </p>
          <img src={displayattr} alt="logo"/>
          <p>2. Filter Attribute as show in handheld device  </p>
          <img src={filterattr} alt="logo"/>
          <p>3. Locate Item Screen based on scan attribute  </p>
          <img src={scanattr} alt="logo"/>
        </div>
    </div>
  );
}else if(props.titleMsg === "Product Upload"){
  ProductLevel = (
    <div>
        <div>
        <p>MANDATORY Guidelines	</p>
        <p>1.Master data file must be in the csv format.	</p>
        <p>2.The filename should always be unique.		</p>
        <p>3.The file name should MDATA_DATETIMESTAMP.csv as this always would make it unique.		</p>
        <p>4.Follow the same naming format for SOH files as well.		</p>
        <p>5.The file should have UTF-8 only as the encoding format.		</p>
        <p>6.The file should have pipe as the separator between columns/fields in the file.		</p>
        <p>7.The last field in the file should not end with a pipe.		</p>
        <p>8.The file should not be comma or semi-colon separated.		</p>
        <p>9.The file should not contain extra pipes that exceed the number of attributes mentioned in the Header.		</p>
        <p>10.The file should not contain extra fields/column that exceed the number of attributes mentioned in the Header.		</p>
        <p>11.The file must contain a header.		</p>
        <p>12.The order of the attributes in the Master data file and Halo’s Product Upload property file should strictly match.		</p>
        <p>13.The first file should be a complete one followed by only delta files.		</p>
        <p>14.The order should never be interchanged and should be strictly maintained in the subsequent files or Delta files. 		</p>
        <p>15.The number of fields in the Master data file and Halo’s Product Upload property file should strictly match.		</p>
        <p>16.The number of fields should never be increased or decreased in the subsequent files or Delta files.		</p>
        <p>17.Each column/field must have the appropriate value under it. The values should not be misplaced in the file or mixed. For e.g. the Style field must have the right Style value under it and not under any other field.		</p>
        <p>18.The number of fields in the Product record should match the number of attributes in the header.		</p>
        <p>19.The attributes mentioned in the header should also be pipe separated.		</p>
        <p>20.The file should not contain unwanted spaces between fields.		</p>
        <p>21.The file should not contain any jargon/junk characters if the file contains characters of the different language. The special character double quotes “ is not supported.		</p>
        <p>22.There should be no extra blank lines in between the Product records in the file.		</p>
        <p>23.The End of the line should not have extra blank line and spaces.		</p>
        <p>24.The delta Product Master files should follow the same format and guidelines as the 1st correct file provided.		</p>
        <p>SKU and GTIN columns in the file		</p>
        <p>1.SKU and GTIN are mandatory columns in the Master Data file.		</p>
        <p>2.The SKU column should have unique values and must never be duplicated/repeated.		</p>
        <p>3.The value in GTIN column can be as one of the below mentioned options either (a) or (b)		</p>
        <p>a.   Should match the value in SKU column.		</p>
        <p>OR		</p>
        <p>b.   The value in GTIN column should be the value of SKU prefixed with 0s so the length of the GTIN is always 14 digits. Below are few example:		</p>
        <p>1.A 12 digits SKU would need to be prefixed with two 0s to get its GTIN		</p>
        <p>2.A 13 digit EAN would need to be prefixed with one 0 to get its GTIN.		</p>
        <p>3.An 8 digit SKU would need to be prefixed with six 0s to get its GTIN.		</p>
        <p>4.The SKU or 13 digit EAN value should be a valid one.		</p>
        <p>5.All the SKUs and GTINs provided in the Master data file must be as per GS1 standards.		</p>
        </div>
    </div>
);
}else if(props.titleMsg === "Product SOH data"){
  ProductLevel = (
    <div>
        <div>
        <p>Sample SOH file content </p>
          <img src={productsoh} alt="logo"/>
        </div>
    </div>
);
}else if(props.titleMsg === "User details"){
  ProductLevel = (
    <div>
        <div>
        <p>Sample user file content </p>
          <img src={userdetails} alt="logo"/>
        </div>
    </div>
);
}else if(props.titleMsg === "Location Upload"){
  ProductLevel = (
    <div>
        <div>
        <p>Sample Location file content </p>
          <img src={location} alt="logo"/>
        </div>
    </div>
);
}else {
  ProductLevel = (
    <div id="jsdwidget">
      
    </div>
);
}

  return (
    <div>
      <Drawer anchor="right" open={props.openState} onClose={props.handleDrawerToggle}>
      <span style={{padding:"5px",fontSize: "20px"}}><b>{props.titleMsg}</b></span>
      <Button><CancelTwoToneIcon style={{position: 'absolute', right: 0, marginTop: -26}} onClick={props.handleDrawerToggle}/></Button>
      <div style={{position: 'relative',width: "300px",padding:"5px",overflowY: "auto"}}>
        {ProductLevel}
      </div>
      </Drawer>
    </div>
  );
}

SidebarHelp.propTypes = {
    handleDrawerToggle: PropTypes.func,
    open: PropTypes.bool
};


export default SidebarHelp;
/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Meghana
@company : Checkpoint Systems

@description : Notification page will list all the notification
*/
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ServerApi from '../../modules/server-api';

import Button from 'components/CustomButtons/Button.jsx';
import Divider from "@material-ui/core/Divider";
import GridItem from "components/Grid/GridItem.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
  }),
);

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      title: "",
      description: "",
      id: "",
      // time:"",
      open: false,
      notifications: []
    };

  }

  //fetching data from db
  componentDidMount() {
    const { id } = this.props.match.params; //id passing
    this.setState({ expandedP: id });
    ServerApi.getNotificationId(this, 0, null);
  }

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }
  showNotification(place) {
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      6000
    );
  }

  handleNext = (event, id, rflag) => {
    event.preventDefault();
    const data = new FormData();
    data.append('id', id);
    id = 0;
    id++;
    ServerApi.getNotificationId(this, id, null); //calling next page
  }
  handlePrev = (event, id, rflag) => {
    event.preventDefault();
    const data = new FormData();
    data.append('id', id);
    if (id >= 0) {
      for (id; id >= 0; id--) {
        ServerApi.getNotificationId(this, id, null); //calling previous page
      }
    }
  }

  handleDialog = (event, id, title, description, time) => {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      title: title,
      description: description,
      time: time,
      id: id
    });
  };

  handleCloseDialog = (e, id) => {
    const data = new FormData();
    data.append('id', id);
    ServerApi.setNotificationRead(id, this);
    this.setState({ open: false });
  };
  render() {

    var { notifications, id, title, description, open, time } = this.state;

    const { classes } = this.props;

    const isEnabled = notifications.length < 10;

    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{ borderTop: "5px solid #4d933c" }}>
          </CardHeader>
          {notifications.map((prop, key) => {
            return (
              <CardBody>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
               
                <div className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Typography className={useStyles.heading}
                        onClick={event => this.handleDialog(event, prop.id, prop.title, prop.msg, prop.time)}>{prop.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography color="textSecondary" className={useStyles.secondaryHeading}>
                        <Moment format="YYYY/MM/DD - HH:mm">
                          {prop.time}
                        </Moment>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                </Typography>
                <Divider />
              </CardBody>
            );
          })}
        </Card>
        <Grid item xs={12} sm={12} xl={12}>
          <Button color="primary" disabled={!isEnabled}
            style={{ marginLeft: "auto", float: "left" }}
            onClick={event => this.handlePrev(event, id, null)}>Prev</Button>

          <Button color="primary" disabled={isEnabled}
            style={{ marginLeft: "auto", float: "right" }}
            onClick={event => this.handleNext(event, id, null)}>Next</Button>
        </Grid>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open} onClose={this.handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Typography className={useStyles.heading}>
              Sent on : (<Moment format="YYYY/MM/DD - HH:mm">{time}</Moment>)
          </Typography>
            <Divider />
            <Typography className={useStyles.secondaryHeading}>
              {description}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" id={id} onClick={event => this.handleCloseDialog(event, id)} >
              Cancel
                </Button>
          </DialogActions>
        </Dialog>
        </GridItem>
      </GridContainer>
    );
  }
}


Notifications.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(Notifications);
import React, { Component } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Markers from './VenueMarkers';
import 'leaflet/dist/leaflet.css';
import ServerApi from '../../modules/server-api';

import MarkerClusterGroup from 'react-leaflet-markercluster';

class MapView extends Component {
 
  constructor(props) {
    
    super(props);
    this.state = {
      currentLocation: { lat: 49.431742741389535, lng:7.159652709960938 },
      //currentLocation: { lat: 12.972442, lng: 77.580643 },
      zoom: 8,
      venues: []
    }
  }

  componentDidMount() {
     ServerApi.fetchDevices(this, this.props.data);
     this.timer = setInterval(() => ServerApi.fetchDevices(this, this.props.data), 10000);
  }

  render() {
    const { currentLocation, zoom, venues } = this.state;

    return (
      <MapContainer center={currentLocation} zoom={zoom}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <MarkerClusterGroup>
            <Markers venues={venues} dragFlag={true}/>
        </MarkerClusterGroup>
      </MapContainer>
    );
  }
}

export default MapView;
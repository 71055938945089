/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S 
@company : Checkpoint Systems

@description : Onboarding Template for Pilot / Production deployment 
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Select, CardActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import SidebarHelp from "components/Sidebar/SidebarHelp";

//import ServerApi from '../../modules/server-api';
import Divider from '@material-ui/core/Divider';

import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

class Onboarding extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      retailername:"",
      prjScopeStores:3,
      dcCount:0,
      prjDuration:6,
      prjLocation:"",
      mobileSSE:"In Scope",
      mobileCC:"In Scope",
      mobileTCC:"No",
      mobileProductLkp: "No",
      mobileTaskMgmt:  "No",
      mobileRecvItem :  "No",
      mobileReturnItem :  "No",
      mobileRecvASN : "No",
      mobileEncodeDisplayItem:"In Scope",
      mobileLocateDisplay:"In Scope",
      mobileFCC: "No",
      mobileLocateItems:"In Scope",
      mobileItemEnquiry:"In Scope",
      mobileMoveItems:  "No",
      mobileReplenish:  "No",
      mobileRetireItem:  "No",
      mobileTransfer:  "No",
      mobileCountDisplay:"In Scope",
      mobileReplenishDisplay:"In Scope",
      dcPrintEncode:"No",
      dcIdentShip:"No",
      portalStoreConsole: "In Scope",
      portalCurrentInv: "In Scope",
      portalCCAccp: "In Scope",
      portalCCAccu: "In Scope",
      portalTagging: "In Scope",
      portalInvComp: "In Scope",
      portalTaskStatus: "In Scope",
      portalSchStatus: "In Scope",
      portalRecv: "No",
      portalReplenishment: "No",
      portalPutAway: "No",
      portalPutAwayRepln: "No",
      portalRetire: "No",
      portalReturn: "No",
      portalPOSSOld: "No",
      portalPOE: "No",
      portalDispC: "In Scope",
      haloPackage:"Basic",
      dcPackage:"DCPrinting",
      RFIDTagInitial: "No",
      RFIDTagProvider: "Checkpoint",
      RFIDSSE: "In Scope",
      RFIDGS1: "8",
      RFIDPrintEncode: "No",
      RFIDSourceTag:"Yes",
      RFIDReaders:"In Scope",
      RFIDHwProv:"Client",
      RFIDHwBarPrinter:"No",
      RFIDHwPos:"No",
      RFIDHwPoe:"No",
      KPISAT:"In Scope",
      KPIPAT:"In Scope",
      KPICAT:"In Scope",
      KPISOHDistS:"In Scope",
      KPISKUVari:"In Scope",
      KPIForzSKU: "In Scope", 
      KPIHiddSKU: "In Scope",
      KPIRefillComp:"No",
      KPIRFIDProc: "In Scope",
      KPISFBD:"No",
      KPICCTime: "In Scope",
      sidebarOpen: false,
      titleMsg:"",
      displayComp:"No"
    }
  }
  componentDidMount() {
  }

  validateTextFld(event, id) {

  }

  updateMobileAppFeature(event){

    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  updateDCAppFeature(event){

    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  updatePortalFeature(event){

    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  validatePackage(event){
    this.setState({haloPackage:event.target.value});
    this.setUpPackage(event.target.value);   
  }

  setUpPackage(typeId){
    if (typeId === "Basic"){
      this.setState({
        mobileSSE:"In Scope",
        mobileCC:"In Scope",
        mobileTCC:"No",
        mobileProductLkp: "No",
        mobileTaskMgmt:  "No",
        mobileRecvItem :  "No",
        mobileReturnItem :  "No",
        mobileRecvASN : "No",
        mobileEncodeDisplayItem:"In Scope",
        mobileLocateDisplay:"In Scope",
        mobileFCC: "No",
        mobileLocateItems:"In Scope",
        mobileItemEnquiry:"In Scope",
        mobileMoveItems:  "No",
        mobileReplenish:  "No",
        mobileRetireItem:  "No",
        mobileTransfer:  "No",
        mobileCountDisplay:"In Scope",
        mobileReplenishDisplay:"In Scope",
        portalStoreConsole: "In Scope",
        portalCurrentInv: "In Scope",
        portalCCAccp: "In Scope",
        portalCCAccu: "In Scope",
        portalTagging: "In Scope",
        portalInvComp: "In Scope",
        portalTaskStatus: "In Scope",
        portalSchStatus: "In Scope",
        portalRecv: "No",
        portalReplenishment: "No",
        portalPutAway: "No",
        portalPutAwayRepln: "No",
        portalRetire: "No",
        portalReturn: "No",
        portalPOSSOld: "No",
        portalPOE: "No",
        portalDispC: "In Scope",
        RFIDTagInitial: "No",
        RFIDTagProvider: "Checkpoint",
        RFIDSSE: "In Scope",
        RFIDGS1: "8",
        RFIDPrintEncode: "No",
        RFIDSourceTag:"Yes",
        RFIDReaders:"In Scope",
        RFIDHwProv:"Client",
        RFIDHwBarPrinter:"No",
        RFIDHwPos:"No",
        RFIDHwPoe:"No",
        KPISAT:"In Scope",
        KPIPAT:"In Scope",
        KPICAT:"In Scope",
        KPISOHDistS:"In Scope",
        KPISKUVari:"In Scope",
        KPIForzSKU: "In Scope", 
        KPIHiddSKU: "In Scope",
        KPIRefillComp:"No",
        KPIRFIDProc: "In Scope",
        KPISFBD:"No",
        KPICCTime: "In Scope",
        displayComp:"No"
      });
    }else if (typeId === "Standard"){
      this.setState({
        mobileSSE:"No",
        mobileCC:"No",
        mobileTCC:"No",
        mobileProductLkp: "No",
        mobileTaskMgmt:  "No",
        mobileRecvItem :  "No",
        mobileReturnItem :  "No",
        mobileRecvASN : "No",
        mobileEncodeDisplayItem:"In Scope",
        mobileLocateDisplay:"In Scope",
        mobileFCC: "No",
        mobileLocateItems:"In Scope",
        mobileItemEnquiry:"In Scope",
        mobileMoveItems:  "No",
        mobileReplenish:  "No",
        mobileRetireItem:  "No",
        mobileTransfer:  "No",
        mobileCountDisplay:"In Scope",
        mobileReplenishDisplay:"In Scope",
        portalStoreConsole: "In Scope",
        portalCurrentInv: "In Scope",
        portalCCAccp: "In Scope",
        portalCCAccu: "In Scope",
        portalTagging: "In Scope",
        portalInvComp: "In Scope",
        portalTaskStatus: "In Scope",
        portalSchStatus: "In Scope",
        portalRecv: "No",
        portalReplenishment: "No",
        portalPutAway: "No",
        portalPutAwayRepln: "No",
        portalRetire: "No",
        portalReturn: "No",
        portalPOSSOld: "No",
        portalPOE: "No",
        portalDispC: "In Scope",
        RFIDTagInitial: "No",
        RFIDTagProvider: "Checkpoint",
        RFIDSSE: "In Scope",
        RFIDGS1: "8",
        RFIDPrintEncode: "No",
        RFIDSourceTag:"Yes",
        RFIDReaders:"In Scope",
        RFIDHwProv:"Client",
        RFIDHwBarPrinter:"No",
        RFIDHwPos:"No",
        RFIDHwPoe:"No",
        KPISAT:"In Scope",
        KPIPAT:"In Scope",
        KPICAT:"In Scope",
        KPISOHDistS:"In Scope",
        KPISKUVari:"In Scope",
        KPIForzSKU: "In Scope", 
        KPIHiddSKU: "In Scope",
        KPIRefillComp:"No",
        KPIRFIDProc: "In Scope",
        KPISFBD:"No",
        KPICCTime: "In Scope",
        displayComp:"No"
      });
    }else if (typeId === "Advanced"){
      this.setState({
        mobileSSE:"In Scope",
        mobileCC:"No",
        mobileTCC:"No",
        mobileProductLkp: "No",
        mobileTaskMgmt:  "No",
        mobileRecvItem :  "No",
        mobileReturnItem :  "No",
        mobileRecvASN : "No",
        mobileEncodeDisplayItem:"In Scope",
        mobileLocateDisplay:"In Scope",
        mobileFCC: "No",
        mobileLocateItems:"In Scope",
        mobileItemEnquiry:"In Scope",
        mobileMoveItems:  "No",
        mobileReplenish:  "No",
        mobileRetireItem:  "No",
        mobileTransfer:  "No",
        mobileCountDisplay:"In Scope",
        mobileReplenishDisplay:"In Scope",
        portalStoreConsole: "In Scope",
        portalCurrentInv: "In Scope",
        portalCCAccp: "In Scope",
        portalCCAccu: "In Scope",
        portalTagging: "In Scope",
        portalInvComp: "In Scope",
        portalTaskStatus: "In Scope",
        portalSchStatus: "In Scope",
        portalRecv: "No",
        portalReplenishment: "No",
        portalPutAway: "No",
        portalPutAwayRepln: "No",
        portalRetire: "No",
        portalReturn: "No",
        portalPOSSOld: "No",
        portalPOE: "No",
        portalDispC: "In Scope",
        RFIDTagInitial: "No",
        RFIDTagProvider: "Checkpoint",
        RFIDSSE: "In Scope",
        RFIDGS1: "8",
        RFIDPrintEncode: "No",
        RFIDSourceTag:"Yes",
        RFIDReaders:"In Scope",
        RFIDHwProv:"Client",
        RFIDHwBarPrinter:"No",
        RFIDHwPos:"No",
        RFIDHwPoe:"No",
        KPISAT:"In Scope",
        KPIPAT:"In Scope",
        KPICAT:"In Scope",
        KPISOHDistS:"In Scope",
        KPISKUVari:"In Scope",
        KPIForzSKU: "In Scope", 
        KPIHiddSKU: "In Scope",
        KPIRefillComp:"No",
        KPIRFIDProc: "In Scope",
        KPISFBD:"No",
        KPICCTime: "In Scope",
        displayComp:"No"
      });
    }else if (typeId === "Display"){
      this.setState({
        mobileSSE:"In Scope",
        mobileCC:"In Scope",
        mobileTCC:"In Scope",
        mobileProductLkp: "No",
        mobileTaskMgmt:  "No",
        mobileRecvItem :  "No",
        mobileReturnItem :  "No",
        mobileRecvASN : "No",
        mobileEncodeDisplayItem:"In Scope",
        mobileLocateDisplay:"In Scope",
        mobileFCC: "No",
        mobileLocateItems:"In Scope",
        mobileItemEnquiry:"In Scope",
        mobileMoveItems:  "No",
        mobileReplenish:  "No",
        mobileRetireItem:  "No",
        mobileTransfer:  "No",
        mobileCountDisplay:"In Scope",
        mobileReplenishDisplay:"In Scope",
        portalStoreConsole: "In Scope",
        portalCurrentInv: "In Scope",
        portalCCAccp: "In Scope",
        portalCCAccu: "In Scope",
        portalTagging: "In Scope",
        portalInvComp: "In Scope",
        portalTaskStatus: "In Scope",
        portalSchStatus: "In Scope",
        portalRecv: "No",
        portalReplenishment: "No",
        portalPutAway: "No",
        portalPutAwayRepln: "No",
        portalRetire: "No",
        portalReturn: "No",
        portalPOSSOld: "No",
        portalPOE: "No",
        portalDispC: "In Scope",
        RFIDTagInitial: "No",
        RFIDTagProvider: "Checkpoint",
        RFIDSSE: "In Scope",
        RFIDGS1: "8",
        RFIDPrintEncode: "No",
        RFIDSourceTag:"Yes",
        RFIDReaders:"In Scope",
        RFIDHwProv:"Client",
        RFIDHwBarPrinter:"No",
        RFIDHwPos:"No",
        RFIDHwPoe:"No",
        KPISAT:"In Scope",
        KPIPAT:"In Scope",
        KPICAT:"In Scope",
        KPISOHDistS:"In Scope",
        KPISKUVari:"In Scope",
        KPIForzSKU: "In Scope", 
        KPIHiddSKU: "In Scope",
        KPIRefillComp:"No",
        KPIRFIDProc: "In Scope",
        KPISFBD:"No",
        KPICCTime: "In Scope",
        displayComp:"No"
      });
    }
  }
  validateDCPackage(event){
    this.setState({dcPackage:event.target.value});
    this.setUpDCPackage(event.target.value);
  }

  setUpDCPackage(typeId){
    if (typeId === "DCPrinting"){
      this.setState({
        dcPrintEncode:"In Scope",
        dcIdentShip:"No"
      });
    }else if (typeId === "DCShipping"){
        this.setState({
          dcPrintEncode:"No",
          dcIdentShip:"In Scope"
        });
    }else{
        this.setState({
          dcPrintEncode:"In Scope",
          dcIdentShip:"In Scope"
        });
    }
  }
  updateRFIDTagFeature(event){
    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  updateRFIDHardware(event){
    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  updateKPI(event){
    const name = event.target.name;
    this.setState({[name] :  event.target.value});
  }

  onSetSidebarOpen(event){
    this.setState({ 
      titleMsg: event.currentTarget.id,
      sidebarOpen: true
    });
  }

  handleDrawerToggle = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  render() {
    const { classes } = this.props;
    const { sidebarOpen,titleMsg } = this.state;

    return (
      <div>
         <SidebarHelp
          openState={sidebarOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          titleMsg={titleMsg}
          >
        </SidebarHelp>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader style={{ borderTop: "5px solid #4d933c" }}>
                <Typography>HALO Onboarding Template - Beta
                    <Tooltip title="HALO Pilot Onboarding"><HelpIcon fontSize="small" style={{ paddingLeft: "5px", position: 'absolute' }} id="Halo Pilot Onboarding" onClick={(e) => this.onSetSidebarOpen(e)} /></Tooltip> &nbsp;&nbsp;
                  </Typography>
              </CardHeader>
              <Divider />
              <CardBody>
                <Paper className={classes.paper} style={{ padding: 10, boxShadow: "0 0 0 0" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={3} style={{ margin: 0 }}>
                        <Grid item xs={4}>
                          <TextField
                            required
                            id="retailername"
                            name="retailername"
                            label="Retailer Name"
                            fullWidth
                            defaultValue={this.state.retailername}
                            style={{ margin: 0 }}
                            onChange={event => this.validateTextFld(event, "retailername")}
                          />
                        </Grid>
                      </Grid>
                      <b>Project Scope</b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={6}>
                        <TextField
                            required
                            id="prjScopeStores"
                            name="prjScopeStores"
                            label="Number of Stores in Project"
                            fullWidth
                            defaultValue={this.state.prjScopeStores}
                            style={{ margin: 0 }}
                            onChange={event => this.validateTextFld(event,"prjScopeStores")}
                          />
                           <TextField
                            required
                            id="dcCount"
                            name="dcCount"
                            label="Number of DC's in Project"
                            fullWidth
                            defaultValue={this.state.dcCount}
                            style={{ margin: 0 }}
                            onChange={event => this.validateTextFld(event,"dcCount")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                            required
                            id="prjDuration"
                            name="prjDuration"
                            label="Project Duration in Months"
                            fullWidth
                            defaultValue={this.state.prjDuration}
                            style={{ margin: 0 }}
                            onChange={event => this.validateTextFld(event,"prjDuration")}
                          />
                           <TextField
                            required
                            id="prjLocation"
                            name="prjLocation"
                            label="Location/Country of the Pilot"
                            fullWidth
                            defaultValue={this.state.prjLocation}
                            style={{ margin: 0 }}
                            onChange={event => this.validateTextFld(event,"prjLocation")}
                          />
                        </Grid>
                      </Grid>
                      <b>HALO SaaS Subscription </b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                      <Grid item xs={3}>
                      <Card className={classes.root}>
                      <CardHeader style={{ borderTop: "10px solid #fcba03" }}>
                        </CardHeader>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
              HALO INVENTORY MANAGER BASIC
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
              Providing The Client’s stores with the ability to Encode Tags and use RFID to Count & Locate their Inventory.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
         <input
              type="radio"
              value="Basic"
              checked={this.state.haloPackage === "Basic"}
              onChange={event => this.validatePackage(event)}
            />
      </CardActions>
    </Card>
    </Grid>
    <Grid item xs={3}>
      <Card className={classes.root}>
      <CardHeader style={{ borderTop: "10px solid #9dfc03" }}>
      </CardHeader>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          HALO INVENTORY MANAGER STANDARD
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Providing The Client’s stores with the ability to Encode Tags and use RFID to Receive, Count, Replenish, Return, Retire, Locate and Move their Inventory within their stores and set RFID-based and free-form Tasks.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <input
              type="radio"
              value="Standard"
              checked={this.state.haloPackage === "Standard"}
              onChange={event => this.validatePackage(event)}
            />
      </CardActions>
    </Card>
    </Grid>
    <Grid item xs={3}>
      <Card className={classes.root}>
      <CardHeader style={{ borderTop: "10px solid #03fcd7" }}>
      </CardHeader>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          HALO INVENTORY MANAGER ADVANCED
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Providing The Client’s stores with the ability to Encode Tags and use RFID to Receive and compare their deliveries against ASN’s, Count, Replenish, Locate, Retire and Move their Inventory within their stores, Return Items to their stock for refunds and/or item exchange and set RFID-based and free-form Tasks.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <input
              type="radio"
              value="Advanced"
              checked={this.state.haloPackage === "Advanced"}
              onChange={event => this.validatePackage(event)}
            />
      </CardActions>
    </Card>
    </Grid>
    </Grid>
    <b>DC Subscription </b>
      <Grid container spacing={3} style={{ margin: 10 }}>
      <Grid item xs={3}>
      <Card className={classes.root}>
      <CardHeader style={{ borderTop: "10px solid #6f03fc" }}>
      </CardHeader>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          HALO DC PRINTING
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Providing The Client’s DC with the ability to Print and Encode Tags.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <input
              type="radio"
              value="DCPrinting"
              checked={this.state.dcPackage === "DCPrinting"}
              onChange={event => this.validateDCPackage(event)}
            />
      </CardActions>
    </Card>
    </Grid>
    <Grid item xs={3}>
    <Card className={classes.root}>
    <CardHeader style={{ borderTop: "10px solid #db03fc" }}>
      </CardHeader>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
          HALO DC SHIPPING
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           Providing The Client’s DC with the ability to Identify & Track outgoing shipments to The Client’s stores using RFID.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <input
              type="radio"
              value="DCShipping"
              checked={this.state.dcPackage === "DCShipping"}
              onChange={event => this.validateDCPackage(event)}
            />
      </CardActions>
    </Card>
    </Grid>
                    <Grid item xs={3}>
                      <Card className={classes.root}>
                      <CardHeader style={{ borderTop: "10px solid #fc035e" }}>
      </CardHeader>
                      <CardActionArea>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                          HALO DC PRINTING & SHIPPING
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            Providing The Client’s DC with the ability to Print and Encode Tags and use RFID to Identify & Track outgoing shipments to The Client’s stores.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                      <input
              type="radio"
              value="DCPrintingShipping"
              checked={this.state.dcPackage === "DCPrintingShipping"}
              onChange={event => this.validateDCPackage(event)}
            />
                      </CardActions>
                    </Card>
                    </Grid>
                    </Grid>
                    <b>Display Compliance</b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                      Providing The Client’s stores with the ability to Encode Tags and use RFID to Count, Locate and Replenish their Inventory used exclusively for Merchandise Display.
                      &nbsp;&nbsp;
                      <Select
                              labelId="displayComp-label"
                              label="Display Compliance"
                              value={this.state.displayComp}
                              onChange={event => this.updateDisplayComplianceSelection(event)}
                              inputProps={{
                                name: 'displayComp',
                                id: 'displayComp',
                              }}
                            >
                              <MenuItem value="In Scope">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>

                      </Grid>
                      <b>Mobile App features</b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={3} style={{ padding: 10 }}>
                            Scan-Scan-Encode Inventory Tags &nbsp;&nbsp;
                          
                            <Select
                              labelId="mobileSSE-label"
                              label="Scan-Scan-Encode Inventory Tags"
                              value={this.state.mobileSSE}
                              onChange={event => this.updateMobileAppFeature(event)}
                              inputProps={{
                                name: 'mobileSSE',
                                id: 'mobileSSE',
                              }}
                            >
                              <MenuItem value="In Scope">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Cycle Count &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileCC-label"
                            label="Scan-Scan-Encode Inventory Tags"
                            value={this.state.mobileCC}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileCC',
                              id: 'mobileCC',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Team Cycle Count &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileTCC-label"
                            label="Team Cycle Count"
                            value={this.state.mobileTCC}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileTCC',
                              id: 'mobileTCC',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Product Lookup (Android only) &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileProductLkp-label"
                            label="Product Lookup (Android only"
                            value={this.state.mobileProductLkp}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileProductLkp',
                              id: 'mobileProductLkp',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3} style={{ padding: 10 }}>
                        Task Management &nbsp;&nbsp;
                          
                            <Select
                              labelId="mobileTaskMgmt-label"
                              label="Task Management"
                              value={this.state.mobileTaskMgmt}
                              onChange={event => this.updateMobileAppFeature(event)}
                              inputProps={{
                                name: 'mobileTaskMgmt',
                                id: 'mobileTaskMgmt',
                              }}
                            >
                              <MenuItem value="In Scope">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Receive Items (w/out ASN) &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileRecvItem-label"
                            label="Receive Items (w/out ASN)"
                            value={this.state.mobileRecvItem}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileRecvItem',
                              id: 'mobileRecvItem',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Return Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileReturnItem-label"
                            label="Return Items"
                            value={this.state.mobileReturnItem}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileReturnItem',
                              id: 'mobileReturnItem',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Receive Items (with ASN) &nbsp;&nbsp;
                          <Select
                            labelId="mobileRecvASN-label"
                            label="Receive Items (with ASN)"
                            value={this.state.mobileRecvASN}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileRecvASN',
                              id: 'mobileRecvASN',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={3} style={{ padding: 10 }}>
                        Encode Display Items &nbsp;&nbsp;
                          <Select
                              labelId="mobileEncodeDisplayItem-label"
                              label="Encode Display Items"
                              value={this.state.mobileEncodeDisplayItem}
                              onChange={event => this.updateMobileAppFeature(event)}
                              inputProps={{
                                name: 'mobileEncodeDisplayItem',
                                id: 'mobileEncodeDisplayItem',
                              }}
                            >
                              <MenuItem value="In Scope">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Locate Display Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileLocateDisplay-label"
                            label="Locate Display Items"
                            value={this.state.mobileLocateDisplay}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileLocateDisplay',
                              id: 'mobileLocateDisplay',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Filtered Cycle Count &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileFCC-label"
                            label="Filtered Cycle Count"
                            value={this.state.mobileFCC}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileFCC',
                              id: 'mobileFCC',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Locate Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileLocateItems-label"
                            label="Locate Items"
                            value={this.state.mobileLocateItems}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileLocateItems',
                              id: 'mobileLocateItems',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Item Enquiry &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileItemEnquiry-label"
                            label="Item Enquiry"
                            value={this.state.mobileItemEnquiry}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileItemEnquiry',
                              id: 'mobileItemEnquiry',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Move Items (Put-away) &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileMoveItems-label"
                            label="Move Items (Put-away)"
                            value={this.state.mobileMoveItems}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileMoveItems',
                              id: 'mobileMoveItems',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Replenish Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileReplenish-label"
                            label="Replenish Items"
                            value={this.state.mobileReplenish}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileReplenish',
                              id: 'mobileReplenish',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Retire Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileRetireItem-label"
                            label="Scan-Scan-Encode Inventory Tags"
                            value={this.state.mobileRetireItem}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileRetireItem',
                              id: 'mobileRetireItem',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Transfer Items (Store to Store/DC) &nbsp;&nbsp;                          
                          <Select
                            labelId="mobileTransfer-label"
                            label="Transfer Items (Store to Store/DC)"
                            value={this.state.mobileTransfer}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileTransfer',
                              id: 'mobileTransfer',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Count Display Items &nbsp;&nbsp;
                          
                          <Select
                            labelId="mobileCountDisplay-label"
                            label="Count Display Items"
                            value={this.state.mobileCountDisplay}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileCountDisplay',
                              id: 'mobileCountDisplay',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Replenish Display Items &nbsp;&nbsp;                          
                          <Select
                            labelId="mobileReplenishDisplay-label"
                            label="Replenish Display Items"
                            value={this.state.mobileReplenishDisplay}
                            onChange={event => this.updateMobileAppFeature(event)}
                            inputProps={{
                              name: 'mobileReplenishDisplay',
                              id: 'mobileReplenishDisplay',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <b>DC features</b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Print and Encode RFID Tags &nbsp;&nbsp;                          
                          <Select
                            labelId="dcPrintEncode-label"
                            label="Print and Encode RFID Tags"
                            value={this.state.dcPrintEncode}
                            onChange={event => this.updateDCAppFeature(event)}
                            inputProps={{
                              name: 'dcPrintEncode',
                              id: 'dcPrintEncode',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Identify/Track Outgoing Shipments &nbsp;&nbsp;
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            label="Identify/Track Outgoing Shipments"
                            value={this.state.dcIdentShip}
                            onChange={event => this.updateDCAppFeature(event)}
                            inputProps={{
                              name: 'dcIdentShip',
                              id: 'dcIdentShip',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <b>Portal features and reports</b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                      <Grid item xs={3}  style={{ padding: 10 }}>
                          Store Console &nbsp;&nbsp;
                          <Select
                            labelId="portalStoreConsole-label"
                            label="Store Console"
                            value={this.state.portalStoreConsole}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalStoreConsole',
                              id: 'portalStoreConsole',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Current Inventory (RFID-based) &nbsp;&nbsp;
                          <Select
                            labelId="portalCurrentInv-label"
                            label="Current Inventory (RFID-based)"
                            value={this.state.portalCurrentInv}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalCurrentInv',
                              id: 'portalCurrentInv',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Cycle Count Acceptance &nbsp;&nbsp;
                          
                          <Select
                             labelId="portalCCAccp-label"
                             label=" Cycle Count Acceptance"
                             value={this.state.portalCCAccp}
                             onChange={event => this.updatePortalFeature(event)}
                             inputProps={{
                               name: 'portalCCAccp',
                               id: 'portalCCAccp',
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Cycle Count (Detail & Accuracy) &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalCCAccu-label"
                            label="Cycle Count (Detail & Accuracy)"
                            value={this.state.portalCCAccu}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalCCAccu',
                              id: 'portalCCAccu',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Inventory Comparison &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalInvComp-label"
                            label="Inventory Comparison"
                            value={this.state.portalInvComp}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalInvComp',
                              id: 'portalInvComp',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Tagging (Items Encoded) &nbsp;&nbsp;
                          
                          <Select
                           labelId="portalTagging-label"
                           label="Tagging (Items Encoded)"
                           value={this.state.portalTagging}
                           onChange={event => this.updatePortalFeature(event)}
                           inputProps={{
                             name: 'portalTagging',
                             id: 'portalTagging',
                           }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Tasks Status &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalTaskStatus-label"
                            label=" Tasks Status"
                            value={this.state.portalTaskStatus}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalTaskStatus',
                              id: 'portalTaskStatus',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Schedule Tasks &nbsp;&nbsp;
                          
                          <Select
                             labelId="portalSchStatus-label"
                             label="Schedule Tasks"
                             value={this.state.portalSchStatus}
                             onChange={event => this.updatePortalFeature(event)}
                             inputProps={{
                               name: 'portalSchStatus',
                               id: 'portalSchStatus',
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Receiving &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalRecv-label"
                            label="Receiving"
                            value={this.state.portalRecv}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalRecv',
                              id: 'portalRecv',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Replenishment &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalReplenishment-label"
                            label="Replenishment"
                            value={this.state.portalReplenishment}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalReplenishment',
                              id: 'portalReplenishment',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Put-away Replenishment &nbsp;&nbsp;
                          
                          <Select
                           labelId="portalPutAwayRepln-label"
                           label=" Put-away Replenishment"
                           value={this.state.portalPutAwayRepln}
                           onChange={event => this.updatePortalFeature(event)}
                           inputProps={{
                             name: 'portalPutAwayRepln',
                             id: 'portalPutAwayRepln',
                           }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Put-away &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalPutAway-label"
                            label="Put Away"
                            value={this.state.portalPutAway}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalPutAway',
                              id: 'portalPutAway',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Retirement &nbsp;&nbsp;
                          
                          <Select
                           labelId="portalRetire-label"
                           label="Retirement"
                           value={this.state.portalRetire}
                           onChange={event => this.updatePortalFeature(event)}
                           inputProps={{
                             name: 'portalRetire',
                             id: 'portalRetire',
                           }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Returns &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalReturn-label"
                            label="Return"
                            value={this.state.portalReturn}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalReturn',
                              id: 'portalReturn',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Point of Sale (Items Sold) &nbsp;&nbsp;
                          
                          <Select
                            labelId="portalPOSSOld-label"
                            label="Point of Sale (Items Sold)"
                            value={this.state.portalPOSSOld}
                            onChange={event => this.updatePortalFeature(event)}
                            inputProps={{
                              name: 'portalPOSSOld',
                              id: 'portalPOSSOld',
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Point of Exit &nbsp;&nbsp;
                          
                          <Select
                             labelId="portalPOE-label"
                             label="Point of Exit"
                             value={this.state.portalPOE}
                             onChange={event => this.updatePortalFeature(event)}
                             inputProps={{
                               name: 'portalPOE',
                               id: 'portalPOE',
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Display Compliance &nbsp;&nbsp;
                          
                          <Select
                             labelId="portalDispC-label"
                             label="Display Compliance"
                             value={this.state.portalDispC}
                             onChange={event => this.updatePortalFeature(event)}
                             inputProps={{
                               name: 'portalDispC',
                               id: 'portalDispC',
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <b>
                        RFID tagging
                        </b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={3}  style={{ padding: 10 }}>

                        Initial Tag-up/Tagging Party at Store &nbsp;&nbsp;
                          
                          <Select
                              labelId="RFIDTagInitial-label"
                              label="Initial Tag-up/Tagging Party at Store"
                              value={this.state.RFIDTagInitial}
                              onChange={event => this.updateRFIDTagFeature(event)}
                              inputProps={{
                                name: 'RFIDTagInitial',
                                id: 'RFIDTagInitial',
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Tag Provider &nbsp;&nbsp;
                          <Select
                            labelId="RFIDTagProvider-label"
                            label="Tag Provider "
                            value={this.state.RFIDTagProvider}
                            onChange={event => this.updateRFIDTagFeature(event)}
                            inputProps={{
                              name: 'RFIDTagProvider',
                              id: 'RFIDTagProvider',
                            }}
                          >
                            <MenuItem value="Checkpoint">Checkpoint</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Scan-Scan-Encode Tags in Store &nbsp;&nbsp;                            
                            <Select
                               labelId="RFIDSSE-label"
                               label="Scan-Scan-Encode Tags in Store"
                               value={this.state.RFIDSSE}
                               onChange={event => this.updateRFIDTagFeature(event)}
                               inputProps={{
                                 name: 'RFIDSSE',
                                 id: 'RFIDSSE',
                               }}
                            >
                              <MenuItem value="In Scope">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={3}  style={{ padding: 10 }}>

                          Default GS1 Company Prefix in Halo &nbsp;&nbsp;
    
                            <Select
                                labelId="RFIDGS1-label"
                                label="Default GS1 Company Prefix in Halo"
                                value={this.state.RFIDGS1}
                                onChange={event => this.updateRFIDTagFeature(event)}
                                inputProps={{
                                  name: 'RFIDGS1',
                                  id: 'RFIDGS1'
                            }}
                            >
                              <MenuItem value="7">7</MenuItem>
                              <MenuItem value="8">8</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={3}  style={{ padding: 10 }}>

                          Print & Encode RFID Tags in Store &nbsp;&nbsp;
    
                            <Select
                               labelId="RFIDPrintEncode-label"
                               label="Print & Encode RFID Tags in Store"
                               value={this.state.RFIDPrintEncode}
                               onChange={event => this.updateRFIDTagFeature(event)}
                               inputProps={{
                                 name: 'RFIDPrintEncode',
                                 id: 'RFIDPrintEncode'
                           }}
                            >
                              <MenuItem value="In Scpoe">In Scope</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={3}  style={{ padding: 10 }}>

                          Source Tagging done by Customer/Third Party &nbsp;&nbsp;
    
                            <Select
                              labelId="RFIDSourceTag-label"
                              label=" Source Tagging done by Customer/Third Party"
                              value={this.state.RFIDSourceTag}
                              onChange={event => this.updateRFIDTagFeature(event)}
                              inputProps={{
                                name: 'RFIDSourceTag',
                                id: 'RFIDSourceTag'
                          }}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      <b>
                        RFID hardware requirement
                        </b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Handheld RFID Readers &nbsp;&nbsp;
                          <Select
                           labelId="RFIDReaders-label"
                           label="Handheld RFID Readers"
                           value={this.state.RFIDReaders}
                           onChange={event => this.updateRFIDHardware(event)}
                           inputProps={{
                             name: 'RFIDReaders',
                             id: 'RFIDReaders'
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        <TextField
                            id="readerCount"
                            name="readerCount"
                            label="RFD8500 Hand-held RFID Reader"
                            fullWidth
                            defaultValue="3"
                            style={{ margin: 0 }}
                            onChange={event => this.updateRFIDHardware(event)}
                          />
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                         Mobile device Provider &nbsp;&nbsp;
                          <Select
                             labelId="RFIDHwProv-label"
                             label="Handheld RFID Readers"
                             value={this.state.RFIDHwProv}
                             onChange={event => this.updateRFIDHardware(event)}
                             inputProps={{
                               name: 'RFIDHwProv',
                               id: 'RFIDHwProv'
                              }}
                          >
                            <MenuItem value="Client">Client</MenuItem>
                            <MenuItem value="Checkpoint">Checkpoint</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Store RFID Printer w. Barcode Scanner &nbsp;&nbsp;
                          <Select
                             labelId="RFIDHwBarPrinter-label"
                             label="Store RFID Printer w. Barcode Scanner"
                             value={this.state.RFIDHwBarPrinter}
                             onChange={event => this.updateRFIDHardware(event)}
                             inputProps={{
                               name: 'RFIDHwBarPrinter',
                               id: 'RFIDHwBarPrinter'
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Point-of-Sale RFID Readers &nbsp;&nbsp;
                          <Select
                             labelId="RFIDHwPos-label"
                             label="Point-of-Sale RFID Readers"
                             value={this.state.RFIDHwPos}
                             onChange={event => this.updateRFIDHardware(event)}
                             inputProps={{
                               name: 'RFIDHwPos',
                               id: 'RFIDHwPos'
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Point-of-Exit RFID Readers &nbsp;&nbsp;
                          <Select
                            labelId="RFIDHwPoe-label"
                            label="Point-of-Exit RFID Readers"
                            value={this.state.RFIDHwPoe}
                            onChange={event => this.updateRFIDHardware(event)}
                            inputProps={{
                              name: 'RFIDHwPoe',
                              id: 'RFIDHwPoe'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <b>
                        KPI
                        </b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                      <Grid item xs={3}  style={{ padding: 10 }}>
                          Store Accuracy Trends &nbsp;&nbsp;
                          <Select
                            labelId="KPISAT-label"
                            label="Store Accuracy Trends"
                            value={this.state.KPISAT}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPISAT',
                              id: 'KPISAT'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Product Availability Trends &nbsp;&nbsp;
                          <Select
                           labelId="KPIPAT-label"
                           label="Product Availability Trends"
                           value={this.state.KPIPAT}
                           onChange={event => this.updateKPI(event)}
                           inputProps={{
                             name: 'KPIPAT',
                             id: 'KPIPAT'
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Counts Accuracy Trends &nbsp;&nbsp;
                          <Select
                            labelId="KPICAT-label"
                            label="Counts Accuracy Trends"
                            value={this.state.KPICAT}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPICAT',
                              id: 'KPICAT'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>

                        <Grid item xs={3}  style={{ padding: 10 }}>
                          SOH vs RFID Distribution and Accuracy in Store &nbsp;&nbsp;
                          <Select
                            labelId="KPISOHDistS-label"
                            label="SOH vs RFID Distribution and Accuracy in Store"
                            value={this.state.KPISOHDistS}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPISOHDistS',
                              id: 'KPISOHDistS'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          SKUs Variance Trends in Store &nbsp;&nbsp;
                          <Select
                            labelId="KPISKUVari-label"
                            label="SKUs Variance Trends in Store"
                            value={this.state.KPISKUVari}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPISKUVari',
                              id: 'KPISKUVari'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Frozen SKUs Trends in Store &nbsp;&nbsp;
                          <Select
                             labelId="KPIForzSKU-label"
                             label="Frozen SKUs Trends in Store"
                             value={this.state.KPIForzSKU}
                             onChange={event => this.updateKPI(event)}
                             inputProps={{
                               name: 'KPIForzSKU',
                               id: 'KPIForzSKU'
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Hidden SKUs Trends in Store &nbsp;&nbsp;
                          <Select
                           labelId="KPIHiddSKU-label"
                           label="Hidden SKUs Trends in Store"
                           value={this.state.KPIHiddSKU}
                           onChange={event => this.updateKPI(event)}
                           inputProps={{
                             name: 'KPIHiddSKU',
                             id: 'KPIHiddSKU'
                            }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          Refill Compliance in Store &nbsp;&nbsp;
                          <Select
                             labelId="KPIRefillComp-label"
                             label="Refill Compliance in Store"
                             value={this.state.KPIRefillComp}
                             onChange={event => this.updateKPI(event)}
                             inputProps={{
                               name: 'KPIRefillComp',
                               id: 'KPIRefillComp'
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                          RFID Process Metrics in Store &nbsp;&nbsp;
                          <Select
                            labelId="KPIRFIDProc-label"
                            label=" RFID Process Metrics in Store"
                            value={this.state.KPIRFIDProc}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPIRFIDProc',
                              id: 'KPIRFIDProc'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        Sales Floor / Backroom Distribution in Store &nbsp;&nbsp;
                          <Select
                             labelId="KPISFBD-label"
                             label="Sales Floor / Backroom Distribution in Store"
                             value={this.state.KPISFBD}
                             onChange={event => this.updateKPI(event)}
                             inputProps={{
                               name: 'KPISFBD',
                               id: 'KPISFBD'
                              }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={3}  style={{ padding: 10 }}>
                        CycleCounts - Time Metrics in Store &nbsp;&nbsp;
                          <Select
                            labelId="KPICCTime-label"
                            label="CycleCounts - Time Metrics in Store"
                            value={this.state.KPICCTime}
                            onChange={event => this.updateKPI(event)}
                            inputProps={{
                              name: 'KPICCTime',
                              id: 'KPICCTime'
                             }}
                          >
                            <MenuItem value="In Scope">In Scope</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                      <b>
                        Data exchange
                        </b>
                      <Grid container spacing={3} style={{ margin: 10 }}>
                        <Grid item xs={12}>
                <Typography>Upload Product Master
                <Tooltip title="Upload a Product Master data">
                <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="Product Upload" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.statusMsg}</b></span>
                <span style={{position: 'relative', left: "35px", color: "red" }}><b>{this.state.errormsg3}</b></span>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-productfile"
                  multiple
                  type="file"
                  accept=".csv"
                  onChange={(e) => this.handleCsvDialog(e)}
                />
                <label htmlFor="raised-button-productfile">
                  <Button variant="outlined" component="span" className={classes.button}>
                  Upload
                  </Button>
                  {this.state.fileprogressProduct ?
                    <CircularProgress className={classes.progress} />
                    : null}
                </label>
                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                <Typography>Upload Location
                <Tooltip title="Upload Location">
                <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="Location Upload" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.statusMsg}</b></span>
                <span style={{position: 'relative', left: "35px", color: "red" }}><b>{this.state.errormsg3}</b></span>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-productfile"
                  multiple
                  type="file"
                  accept=".csv"
                  onChange={(e) => this.handleCsvDialog(e)}
                />
                <label htmlFor="raised-button-productfile">
                  <Button variant="outlined" component="span" className={classes.button}>
                  Upload
                  </Button>
                  {this.state.fileprogressProduct ?
                    <CircularProgress className={classes.progress} />
                    : null}
                </label>
                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                <Typography>Upload SOH data
                <Tooltip title="Upload SOH data">
                <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="Product SOH data" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.statusMsg}</b></span>
                <span style={{position: 'relative', left: "35px", color: "red" }}><b>{this.state.errormsg3}</b></span>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-productfile"
                  multiple
                  type="file"
                  accept=".csv"
                  onChange={(e) => this.handleCsvDialog(e)}
                />
                <label htmlFor="raised-button-productfile">
                  <Button variant="outlined" component="span" className={classes.button}>
                  Upload
                  </Button>
                  {this.state.fileprogressProduct ?
                    <CircularProgress className={classes.progress} />
                    : null}
                </label>
                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                <Typography>Upload User details
                <Tooltip title="Upload User details data">
                <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="User details" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.statusMsg}</b></span>
                <span style={{position: 'relative', left: "35px", color: "red" }}><b>{this.state.errormsg3}</b></span>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-productfile"
                  multiple
                  type="file"
                  accept=".csv"
                  onChange={(e) => this.handleCsvDialog(e)}
                />
                <label htmlFor="raised-button-productfile">
                  <Button variant="outlined" component="span" className={classes.button}>
                  Upload
                  </Button>
                  {this.state.fileprogressProduct ?
                    <CircularProgress className={classes.progress} />
                    : null}
                </label>
                </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </CardBody>
              <CardActions>
                <Grid container item xs={12} sm={12} justify="flex-end">
                  
                    <Button
                    variant="contained"
                    color="primary"

                    className={classes.button}
                  >
                    Validate and Submit
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Onboarding.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Onboarding);

/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Admin navigation bar contains header components
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Auth from '../../modules/auth';

import ServerApi from '../../modules/server-api';

class AdminNavbarLinks extends React.Component {

  state = {
    openNotifcation: false,
    openProfile: false,
    notifications: [],
    serverDown: false,
    loginAgain: false,
    open: false,
    msg: '',
    id: ''
  };


  handleChange = (e, value) => {
    var newMessage = e.target.id;

    this.setState({ openNotifcation: false });

    window.location.href = "/notification/pagewise"
      //"/admin/notifications?id=" 
      + newMessage;
  };

  handleToggleNotification = () => {
    this.setState(state => ({ openNotifcation: !state.openNotifcation }));
  };
  handleCloseNotification = event => {
    if (this.anchorNotification.contains(event.target)) {
      return;
    }
    this.setState({ openNotifcation: false });
  };
  handleToggleProfile = () => {
    this.setState(state => ({ openProfile: !state.openProfile }));
  };
  handleCloseProfile = event => {
    if (this.anchorProfile.contains(event.target)) {
      return;
    }
    this.setState({ openProfile: false });
    window.location.href = "/admin/user";
  };
  handleLogout = event => {
    Auth.deauthenticateUser();
    window.location.href = "/login/login";
  };

  //fetching data from db
  handleOk = event => {
    this.setState({ loginAgain: false });
    window.location.href = "/login";
  };

  //fetching data from db
  componentDidMount() {
    //check for login
    ServerApi.sessionStatus(this, false);
    this.timer = setInterval(() => ServerApi.sessionStatus(this, false), 60000);
    this.timer = setInterval(() => ServerApi.getNotification(this, false), 60000);
  }


  handleDialogNav = (e, id, title, description) => {
    e.preventDefault();
    this.setState({
      open: true,
      anchorEl: e.currentTarget,
      title: title,
      id: id,
      description: description,
    });
  };

  handleCloseDialog = (e, id) => {
    const data = new FormData();
    data.append('id', id);
    ServerApi.setNotificationRead(id, this); //taking read notfictn id to backend 
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { openNotifcation, openProfile } = this.state;
    var { notifications, open, title, id, description, serverDown, loginAgain } = this.state;

    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorNotification = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "White"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openNotifcation ? "notification-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleNotification}
            className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
            <span className={classes.notifications}>{notifications.length}</span>
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={openNotifcation}
            anchorEl={this.anchorNotification}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openNotifcation }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  {notifications.map((prop, key) => {
                    return (
                      <ClickAwayListener onClickAway={this.handleCloseNotification}>
                        <MenuList role="menu" >
                          <Typography className={classes.dropdownItem} id={prop.id}
                            onClickCapture={event => this.handleDialogNav(event, prop.id, prop.title, prop.msg)}> {prop.title}
                          </Typography>
                        </MenuList>
                      </ClickAwayListener>
                    )
                  })}
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorProfile = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggleProfile}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Profile</p>
            </Hidden>
          </Button>
          <Poppers
            open={openProfile}
            anchorEl={this.anchorProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleCloseProfile}
                        className={classes.dropdownItem}
                      >
                        Profile
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={this.handleLogout}
                        className={classes.dropdownItem}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={serverDown}
          >
            <DialogTitle id="confirmation-dialog-title">It's not you, It's Us</DialogTitle>
            <DialogContent dividers>
              Our server's are down, but we'll have things back to normal soon, if you still need a help, visit our site at http://www.checkpointsystems.com
          </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>

          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={loginAgain}
          >
            <DialogTitle id="confirmation-dialog-title">Session Experied: Login </DialogTitle>
            <DialogContent dividers>
              Click ok to login again
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleOk} color="primary">
                Ok
            </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open} onClose={this.handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title} </DialogTitle>
            <DialogContent>
              {description}
            </DialogContent>
            <DialogActions>
              <Button id={id} onClick={event => this.handleCloseDialog(event, id)} color="secondary">
                Cancel
                </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

AdminNavbarLinks.propTypes = {
  classes: PropTypes.object
};

export default withStyles(headerLinksStyle)(AdminNavbarLinks);
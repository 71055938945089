/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Dynamic table to show data from azure web hook url 
*/
import React from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

export default class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
  }
  getKeys = function() {
      if (this.props.data[0] === undefined){
        return Object.keys(this.props.data);
      }else{
        return Object.keys(this.props.data[0]);
      }
  }
    
    getHeader = function(){
      var keys = this.getKeys();
     
      return keys.map((key, index)=>{
        return <TableCell key={key}>{key.toUpperCase()}</TableCell>
      })
    }
    
    getRowsData = function(){
      var items = this.props.data;
      var keys = this.getKeys();
      if (items !== null || items !== undefined){
        if (Array.isArray(items)){
        return items.map((row, index)=>{
          return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
       })
      }
      }
    }
    
    render() {
        return (
          <div>
            <Table>
            <TableHead>
              <TableRow>{this.getHeader()}</TableRow>
            </TableHead>
            <TableBody>
            {this.getRowsData()}
            </TableBody>
            </Table>
          </div>
        );
    }
}

const RenderRow = (props) =>{

  return props.keys.map((key, index)=>{
    if (!Array.isArray(props.data[key])){
      return <TableCell key={props.data[key]}>{props.data[key]}</TableCell>
    } else{
      return null
    }
  })
}
/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : User profile screen 
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import EdiText from 'react-editext';
import Avatar from 'react-avatar';
import avatar from "assets/img/faces/Avatar.jpg";
import CardHeader from "components/Card/CardHeader.jsx";

import ServerApi from '../../modules/server-api';
import "./profile.css";
import UpdatePwd from "./UpdatePwd";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  align : {
    marginLeft:"10px",
    marginTop: "10px",
    alignItems: "center"
  },
  popup : {
    fontFamily: "sans-serif",
    textAlign: "center"
  },
  button : {
    color: "Purple",
    marginBottom: "10px",
  },
  paper: {
    maxWidth: 600,
    margin: `10px auto`,
    padding: 20,
  },
};

class UserProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userdata: [],
      open: false,
      selectedImage : avatar,
      msg: ''
    };
    this.inputOpenFileRef = React.createRef();
  }
  handleClick = () => {
    this.props.history.push("/admin/edit");
  }

  onButtonClick = () => {
    // `current` points to the mounted file input element
    this.inputOpenFileRef.current.click();
  };
  showModal = () => {
    this.setState({ open : true });
  };
  hideModal = () => {
    this.setState({ open : false });
  };
  toggleModal = () => {
    this.setState({ open : !this.state.open});
  };
  handleChange = event => {
    let reader = new FileReader();
    reader.onload = (event) => {
      console.log(event.target.result);
      this.setState({
          selectedImage : event.target.result
        });
        const data = new FormData();     
        data.append('thumbnail',  event.target.result);
        ServerApi.updateProfile(data,this);	
    };
    reader.readAsDataURL(event.target.files[0]);   
  };
  onSave = val => {
    //console.log('Edited Value -> ', val)
    const data = new FormData(); 
    data.append('fullname',val);
    ServerApi.updateProfile(data,this);	
  };

  componentDidMount() {
    ServerApi.getUserDetails(this);
    console.log(this.state.selectedImage);
  }

  render() {
    const { classes } = this.props;
    var { userdata} = this.state;
   
    return (   
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card profile >
                <CardAvatar profile>
                  <div className="profile-pic">                
                    <div className="edit"><a href="#pablo" onClick={this.onButtonClick}><i className="fa fa-pencil fa-lg"></i></a></div>
                      <Avatar size="120" number="1.75" src={this.state.selectedImage}
                        name={userdata.username} alt={userdata.username} />                               
                  </div> 
                  <input ref={this.inputOpenFileRef} type="file" onChange={this.handleChange} style={{display:"none"}} accept="image/*" />
                </CardAvatar>
                <CardHeader style={{borderTop: "5px solid #a08ac2"}}>
                </CardHeader>
                <CardBody profile>
                  <h4 className={classes.cardTitle}> 
                    <p> {userdata.email} </p>                               
                    <EdiText className={classes.align} showButtonsOnHover type='text' value={userdata.fullname}
                      //value={ this.state.uploadedImage ? this.state.uploadedImage : "No contents to display." }
                      onSave={this.onSave} />
                  </h4>
                  
                  <Button className={classes.button} onClick={this.showModal}>Change Password</Button>                  
                  <Dialog open={this.state.open} onClose={this.hideModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"> Change Password </DialogTitle>
                      <DialogContent>
                        <UpdatePwd hideModal={this.hideModal}/>                        
                      </DialogContent>                     
                    </Dialog>                                     
                </CardBody>                                  
              </Card>
            </GridItem>
          </GridContainer>   
        </div>       
      );
    }
}

UserProfile.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(UserProfile);
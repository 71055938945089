/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          

@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Deployment detail screen includes product and location 
              uploads and property file changes 
*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ServerApi from '../../modules/server-api';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import Component from "mson-react/lib/component";
import { CardActions } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import compiler from 'mson/lib/compiler';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import Divider from '@material-ui/core/Divider';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import SidebarHelp from "components/Sidebar/SidebarHelp";

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


class CustomerDetail extends React.Component {

  state = {
    deployment: {},
    currentRetail: '',
    isVisible: false,
    openTable: false,
    openDialog: false,
    integrationOpen: false,
    integrationLocOpen: false,
    dynFile: false,
    prodValues: [],
    prodHeaders: [],
    prodAttr: [],
    userProdAttr: [],
    tags: [],
    levelArr: {},
    displayAttr: [],
    filterAttr: [],
    scanAttr: [],
    locations: [],
    locationsDialog: false,
    siteNo: '',
    siteName: '',
    stoCode: '',
    respmsg: '',
    dynfilevalue: '',
    copySuccess: false,
    csvdata: [],
    csvColumn: [],
    csvColumnSize:0,
    uploadHeaders: [],
    openPrdAttr: false,
    filename:'',
    content:[],
    disabled1: false,
    disabled2: false,
    updatemsg1: '',
    updatemsg2: '',
    sidebarOpen: false,
    titleMsg:"" ,
    pic: "",
    errormsg2: "",
    errormsg1: "",
    errormsg: "",
    errormsg3: ""
  };

  componentDidMount() {
    const { rid } = this.props.match.params;
    this.setState({
      currentRetail: rid,
      compiler: compiler
    });
    ServerApi.getCustomerDetails(this, rid);
    ServerApi.getIntegrationType(this, "HALOPORTAL");
    ServerApi.getproperty(this, rid);
  }

  handleFileChange(e) {
    e.preventDefault();
    var self = this;

    self.setState({
      integrationOpen: true,
      anchorEl: e.currentTarget,
      title: this.state.integrationType.details.header,
      description: this.state.integrationType.details.description,
      params: compiler.newComponent(this.state.integrationType.details.parameter),
      authType: this.state.integrationType.details.authType,
      url: this.state.integrationType.details.url,
      fileprogress: true,
      file: e.target.files[0],
      fileCateory: { "filetype": "Location" }
    });
  }

  handleFileChangeProduct(e) {
    e.preventDefault();
    var self = this;

    self.setState({
      integrationOpen: true,
      anchorEl: e.currentTarget,
      title: this.state.integrationType.details.header,
      description: this.state.integrationType.details.description,
      params: compiler.newComponent(this.state.integrationType.details.parameter),
      authType: this.state.integrationType.details.authType,
      url: this.state.integrationType.details.url,
      fileprogressProduct: true,
      fileCateory: { "filetype": "Product" },
      userSelectedAttribute :this.state.userProdAttr,
      filename:this.state.csvfilename
    });
  }

  myChangeHandler = (event) => {
    let nam = event.target.id;
    let val = event.target.value;
    this.setState({ [nam]: val });
  }

  handleClickDialog(e) {
    this.setState({
      locationsDialog: true
    });
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false,
      integrationOpen: false,
      locationsDialog: false,
      integrationLocOpen: false,
      openPrdAttr: false,
      dynFile: false
    });
  }

  handleSiteName(e) {
    this.setState({
      siteName: e.target.value
    });
  }

  handleSiteNumber(e) {
    this.setState({
      siteNo: e.target.value
    });
  }

  handleStoreCode(e) {
    this.setState({
      stoCode: e.target.value
    });
  }

  handleLocationChange(e) {
    this.setState({
      locationsDialog: false,
      integrationLocOpen: true,
      anchorEl: e.currentTarget,
      title: this.state.integrationType.details.header,
      description: this.state.integrationType.details.description,
      params: compiler.newComponent(this.state.integrationType.details.parameter),
      authType: this.state.integrationType.details.authType,
      url: this.state.integrationType.details.url,
      fileprogress: false,
      file: null,
      fileCateory: { "filetype": "AddSite" }
    });
  }

  handleDynamicFile(e){
    this.setState({      
      dynFile: true
    });
    ServerApi.getdynamicfile(this,this.state.currentRetail);
  }

  copytoclipboard=()=>{
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  handleEditFile(e, filename) {
    this.setState({
      description: "Halo Operation Suite Property file editor, selected file is : " + filename
    });
  };

  selectDynamicLevels = (event, values) => {
    if (Array.from(values).length > 7) {
      alert("Max levels is 7");
    } else {
      this.setState({ "levelArr": values });
    }
  }
  productLevelConfig(e) {
    ServerApi.postConfigLevels(this.state.levelArr, this.state.currentRetail, this);
    this.setState({disabled1: true});
  }

  productHHConfig(e) {
    ServerApi.hhuiconfig(this.state.displayAttr, this.state.filterAttr, this.state.scanAttr, this.state.currentRetail, this);
    this.setState({disabled2: true});
  }

  onTagsChangeDisplay = (event, values) => {
    console.log(values);
    this.setState({
      displayAttr: values
    });
  }

  onTagsChangeFilter = (event, values) => {
    this.setState({
      filterAttr: values
    });
  }
  onTagsChangeScan = (event, values) => {
    this.setState({
      scanAttr: values
    });
  }

  onSetSidebarOpen=(event) => {
    this.setState({ 
      titleMsg: event.currentTarget.id,
      sidebarOpen: true
    });
}

  handleUsrstrarray = (event) => {
    var uploadHeadersTemp = event.currentTarget.value;
    this.setState({
      uuploadHeaders: uploadHeadersTemp
    });
  }

  handleCsvDialog = (e) => {
    e.preventDefault();
    this.setState({
      openPrdAttr: true
    });
    ServerApi.getCustomtable(this, e.target.files[0]);
  }

  updateHeaderAttr(e,index,popupState){
    const { headerValue } = e.currentTarget.dataset;
    console.log(headerValue);
    var userProdAttrTemp = this.state.userProdAttr;
    userProdAttrTemp[index]=headerValue;
    this.setState({
      userProdAttr: userProdAttrTemp
    });
    popupState.close();
    console.log(this.state.userProdAttr);
  }

  handleDrawerToggle = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  render() {
    const { classes } = this.props;
    const { integrationOpen, integrationLocOpen, title, description, params, url, 
      locations, locationsDialog, openPrdAttr, csvdata, csvColumn,dynFile,filename,userSelectedAttribute,sidebarOpen,titleMsg } = this.state;
    
    return (
      <div>
        <SidebarHelp
          openState={sidebarOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          titleMsg={titleMsg}
          >

        </SidebarHelp>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            Retailer Id :  {this.state.currentRetail}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <Dialog open={locationsDialog} onClose={(e) => this.handleCloseDialog(e)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Location</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="siteName"
                label="Site Name"
                type="text"
                placeholder="Ex: SAM"
                fullWidth
                onChange={(e) => this.handleSiteName(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.handleCloseDialog(e)} color="primary">
                Cancel
          </Button>
              <Button onClick={(e) => this.handleLocationChange(e)} color="primary">
                Add Location
          </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={integrationLocOpen} onClose={(e) => this.handleCloseDialog(e)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> {title}</DialogTitle>
            <DialogContent>
              {description}
              <Component component={params}
                onSubmit={({ component }) => {
                  ServerApi.locAuth(component.getValues(), "HALOPORTAL", url, this.state.fileCateory, this.state.siteName, this.state.currentRetail, this);
                }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.handleCloseDialog(e)} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <GridItem xs={12} sm={12} md={12}>
            <Card chart style={{ height: "230px", overflowY: "scroll" }}>
              <CardHeader style={{borderTop: "5px solid #ec7a08"}}>
                <Typography>Location / Site 
                <Tooltip title="List of existing locations with sub-locations"><HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }} id="Existing Locations" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.respmsg}</b></span>
                <span style={{position: 'relative', left: "35px",color: "red"}}><b>{this.state.errormsg}</b></span>
                  <Fab size="small" color="primary" aria-label="add" className={classes.fab} style={{ position: 'absolute', right: 0, marginTop: -10 }} onClick={(e) => this.handleClickDialog(e)}>
                    <Tooltip title="Add a new Site to Location"><AddIcon /></Tooltip>
                  </Fab>
                </Typography>
              </CardHeader>
              <Divider />
              <CardBody>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key="Location">
                        Site Name
                      </TableCell>
                      <TableCell>
                        Sub-Locations
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locations.map((prop, key) => {
                      var storeCode = (prop.site_name).split('-', 1)[0];
                      return (
                        <TableRow key="Location">
                          <TableCell>
                            {prop.site_name}
                          </TableCell>
                          <TableCell>
                            {storeCode}-SalesFloor_RP, {storeCode}-SalesFloor, {storeCode}-Backroom_RP, {storeCode}-Backroom
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader style={{borderTop: "5px solid #ffaaee"}}>
                <Typography>Upload Product Master
                <Tooltip title="Upload a Product Master data"><HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="Product Upload" onClick={(e) => this.onSetSidebarOpen(e)}/></Tooltip> &nbsp;&nbsp;
                <span style={{position: 'relative', left: "35px", color: "green" }}><b>{this.state.statusMsg}</b></span>
                <span style={{position: 'relative', left: "35px", color: "red" }}><b>{this.state.errormsg3}</b></span>
                </Typography>
              </CardHeader>
              <Divider />
              <CardBody>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id="raised-button-productfile"
                  multiple
                  type="file"
                  accept=".csv"
                  onChange={(e) => this.handleCsvDialog(e)}
                />
                <label htmlFor="raised-button-productfile">
                  <Button variant="outlined" component="span" className={classes.button}>
                    Upload
                  </Button>
                  {this.state.fileprogressProduct ?
                    <CircularProgress className={classes.progress} />
                    : null}
                </label>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
            <Dialog disableBackdropClick
              disableEscapeKeyDown
              open={openPrdAttr} onClose={this.handleCloseDialog} aria-labelledby="form-dialog-title">
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      {csvColumn.map((prop, key) => {
                        return (
                          <TableCell key={key}>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                              {popupState => (
                                <React.Fragment>
                                  <Button variant="contained"  color="primary" {...bindTrigger(popupState)}>
                                    {this.state.userProdAttr[key]}
                                  </Button>
                                  <Menu {...bindMenu(popupState)}>
                                    {this.state.prodAttr.map((prop1, key1) => {
                                      return (
                                        <MenuItem data-header-value={prop1}  onClick={event => this.updateHeaderAttr(event, key,popupState)}>{prop1}</MenuItem>
                                      )
                                    })}
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvdata.map((prop, key) => {
                      return (
                        <TableRow key={key}>
                          {prop.map((prop1, key1) => {
                            return (
                              <TableCell key={key1}>{prop1}</TableCell>
                            )
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={event => this.handleCloseDialog(event)} >
                  Cancel
                </Button>
                <Button color="primary" onClick={event => this.handleFileChangeProduct(event)} >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
          <Dialog open={integrationOpen} onClose={(e) => this.handleCloseDialog(e)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> {title}</DialogTitle>
            <DialogContent>
              {description}
              <Component component={params}
                onSubmit={({ component }) => {
                  ServerApi.integrationAuth(this, component.getValues(), "HALOPORTAL", url, this.state.currentRetail, this.state.fileCateory, filename,userSelectedAttribute,this.state.csvColumnSize);
                }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.handleCloseDialog(e)} color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <GridItem xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader style={{borderTop: "5px solid #ff6d65"}}>
              <Typography> Product Hierarchy
              <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }} id="Product Hierarchy" onClick={(e) => this.onSetSidebarOpen(e)}/>
              <Fab size="small" color="primary" aria-label="add" className={classes.fab} style={{ position: 'absolute', right: 0, marginTop: -10 }} onClick={(e) => this.handleDynamicFile(e)}>
              <Tooltip title="Copy Product Metadata to upload at Halo Portal"><FileCopyTwoToneIcon /></Tooltip>
              </Fab>
              <span style={{position: 'relative', left: "35px",color: "green"}}><b>{this.state.updatemsg1}</b></span>
              <span style={{position: 'relative', left: "35px",color: "red"}}><b>{this.state.errormsg1}</b></span>
              </Typography>
              </CardHeader>
              <Divider />
              <CardBody>
                <Autocomplete
                  multiple
                  id="selLevelAttributes"
                  options={this.state.prodAttr}
                  value={this.state.levelArr}

                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Product Level Configuration Attribute"
                      variant="outlined"
                      placeholder="Select Product Level Configuration Attribute"
                      fullWidth
                    />
                  )}
                  onChange={this.selectDynamicLevels}
                />
              </CardBody>
              <CardActions>
                <Button fullWidth onClick={e => this.productLevelConfig(e)} disabled={this.state.disabled1} color="primary">
                  {this.state.disabled ? 'Updating...' : 'UPDATE'}
                </Button>
              </CardActions>
            </Card>
          </GridItem>
          <Dialog open={dynFile} onClose={(e) => this.handleCloseDialog(e)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> 
             Product Metadata &nbsp;&nbsp;
            {this.state.copySuccess ? <span style={{color: "green"}}><b>Text Copied Successfully</b></span>:null}
            </DialogTitle>
            <DialogContent>
                    <textarea rows="18" cols="66" ref={(textarea) => this.textArea = textarea} value={this.state.dynfilevalue}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.handleCloseDialog(e)} color="secondary">
                Close
              </Button>
              <Button onClick={() => this.copytoclipboard()} color="secondary">
                Copy Text
              </Button>
            </DialogActions>
          </Dialog>
          <GridItem xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader style={{borderTop: "5px solid #a08ac2"}}>
                <Typography>Handheld (HHUI) Configuration
                <HelpIcon fontSize="small" style={{paddingLeft:"5px",position: 'absolute' }}  id="HHUI Config" onClick={(e) => this.onSetSidebarOpen(e)}/>
                <span style={{position: 'relative', left: "35px",color: "green"}}><b>{this.state.updatemsg2}</b></span>
                <span style={{position: 'relative',left: '35px',color: "red"}}><b>{this.state.errormsg2}</b></span>
                </Typography>
              </CardHeader>
              <Divider />
              <CardBody key="2">
                <div className={classes.autoComplete}>
                  <Autocomplete
                    multiple
                    id="selDisplayAttributes"
                    options={this.state.prodAttr}
                    value={this.state.displayAttr}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Display Configuration Attribute"
                        variant="outlined"
                        placeholder="Select Display Configuration Attribute"
                        fullWidth
                      />
                    )}
                    onChange={this.onTagsChangeDisplay}
                  />
                </div>
                <div className={classes.autoComplete}>
                  <Autocomplete
                    multiple
                    id="selFilterAttributes"
                    options={this.state.prodAttr}
                    value={this.state.filterAttr}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Filter Configuration Attribute"
                        variant="outlined"
                        placeholder="Select Filter Configuration Attribute"
                        fullWidth
                      />
                    )}
                    onChange={this.onTagsChangeFilter}
                  />
                </div>
                <div className={classes.autoComplete}>
                  <Autocomplete
                    id="selScanAttribute"
                    options={this.state.prodAttr}
                    value={this.state.scanAttr}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Scan Attribute"
                        variant="outlined"
                        placeholder="Select Scan Attribute"
                        fullWidth
                      />
                    )}
                    onChange={this.onTagsChangeScan}
                  />
                </div>
              </CardBody>
              <Button onClick={e => this.productHHConfig(e)} disabled={this.state.disabled2} color="primary">
                {this.state.disabled ? 'Updating...' : 'UPDATE'}
              </Button>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

CustomerDetail.propTypes = {
  classes: PropTypes.object
};

export default withStyles(dashboardStyle)(CustomerDetail);

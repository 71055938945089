/*
   _    _           _              ____                
  | |  | |         | |            / __ \               
  | |__| |   __ _  | |   ___     | |  | |  _ __    ___ 
  |  __  |  / _` | | |  / _ \    | |  | | | '_ \  / __|
  | |  | | | (_| | | | | (_) |   | |__| | | |_) | \__ \
  |_|  |_|  \__,_| |_|  \___/     \____/  | .__/  |___/
                                          | |          
                                          |_|          
@version : v1.0
@author : Subramanya H S
@company : Checkpoint Systems

@description : Dynamic table to show data from device status api
*/
import React from "react";

import MaterialTable from "material-table";
import ServerApi from '../../modules/server-api';

export default class DeviceTableCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venues: []
    }
  }

componentDidMount() {
    ServerApi.fetchDevices(this, this.props.data);
    this.timer = setInterval(() => ServerApi.fetchDevices(this, this.props.data), 10000);
}

render() {
      const { venues } = this.state;
        return (
          <div>
            <MaterialTable
          columns={[
            { title: "Name", field: "name",render: rowData => <div style={{  color: rowData.status}}> {rowData.name}</div> },
            { title: "Description", field: "description" },
            { title: "Store Name", field: "locationId" },
            { title: "Antenna Enabled", field: "antennasEnabled"},
            { title: "Last status change", field: "statusChange"},
            { title: "Status", field:"status",render: rowData => <div style={{ height: "30px",width: "80px",marginBottom: "15px",border: "1px solid black",  backgroundColor: rowData.status}}></div>}
          ]}
          data={venues}
          title=""
          options={{
            exportButton: true
          }}
        />
          </div>
        );
    }
}

import { makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { IRetailerModel } from "interfaces/IRetailerModel";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiFormControl-root": {
            width: "95%",
            margin: theme.spacing(1)
        },
    }
}));

interface IRetailerAutoComplete {
    retailerData: IRetailerModel[] | undefined,
    onchange: (arg: IRetailerModel | null) => void
}

const RetailerAutoComplete: React.FC<IRetailerAutoComplete> = ({ retailerData, onchange }) => {
    const [values, setValues] = useState<IRetailerModel | null>(null);

    useEffect(() => {
        console.log("retailerData changed")
        setValues(null);
    }, [retailerData]);

    const changeValue = (value: IRetailerModel | null) => {
        setValues(value);
    };
    const classes = useStyles();
    return (
        <Autocomplete
            className={classes.root}
            options={retailerData}
            getOptionLabel={(option) => option.retailerName}
            value={values}
            renderInput={(params) => <TextField {...params} label="Select retailer" variant="outlined" />}
            onChange={(event: any, value: IRetailerModel | null) => {
                changeValue(value);
                onchange(value);
            }}
        />

    )
}

export default RetailerAutoComplete;